import React from 'react';
// import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import {connect} from 'react-redux';
import {Button, Form, Input} from 'reactstrap';
import {loginDiabTrendDb, signoutFoodTrend} from '../../redux/foodtrend_actions';

class AuthForm extends React.Component {
  state = {
    user: null,
    password: '',
  };

  isLoggedIn = () => {
    return this.props.food_user;
  };

  handleSubmit = e => {
    e.preventDefault();
    // SIGN OUT
    if (this.isLoggedIn()) {
      this.props.signoutFoodTrend();
      return;
    }
    // LOGIN
    const {email, password} = this.state;
    if (!email || !password) {
      return;
    }
    this.props.loginDiabTrendDb(email, password);
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return this.isLoggedIn() ? (
      <Form
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: 8,
        }}
      >
        <div style={{marginRight: 20, color: 'white', fontWeight: 600}}>
          {this.props.food_user.email}
        </div>
        <Button type="submit" onClick={this.handleSubmit}>
          Logout
        </Button>
      </Form>
    ) : (
      <Form
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: 8,
        }}
      >
        <Input
          style={{marginRight: 8}}
          type="email"
          name="email"
          placeholder="Email"
          value={this.state.email}
          onChange={e => this.handleChange(e)}
        />
        <Input
          style={{marginRight: 8}}
          type="password"
          name="password"
          placeholder="Password"
          value={this.state.password}
          onChange={e => this.handleChange(e)}
        />
        <Button type="submit" onClick={this.handleSubmit}>
          Login
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    food_user: state.user.food,
  };
};

const AuthFormContainer = connect(
  mapStateToProps,
  {loginDiabTrendDb, signoutFoodTrend},
  null,
)(AuthForm);
export default AuthFormContainer;
