import { SET_PHOTOS, UPDATE_PHOTO } from './photos_actions';

export const photos = (state = null, action) => {
  switch (action.type) {
    case SET_PHOTOS:
      return action.photos;
    case UPDATE_PHOTO:
      const newState = state.map(o =>
        o[1].md5 === action.md5
          ? [o[0], { ...o[1], ...action.update }]
          : o[0].md5 === action.md5
          ? [{ ...o[0], ...action.update }, o[1]]
          : o,
      );
      return newState;
    default:
      return state;
  }
};
