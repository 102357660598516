import _ from 'lodash';
import {UNVALIDATED} from './constants';
import {changeFoodContains, changeFoodLectored, changeFoodName, changeFoodSrc, deleteFoodKaloriID} from '../redux/foodtrend_actions';
import {lectoredMap, lectoredNameMap} from './assets_helper';
import React, {useMemo, useState} from 'react';
import {lectorOrder, lectorsNext} from './lector_helper';
import {useFoodDbStore} from '../redux/food_actions';
import {EMPTY_STR} from './utils';
import {format} from 'date-fns';
import {ReactComponent as Comment} from '../assets/comment.svg';
import {ReactComponent as Chef} from '../assets/chef.svg';
import Cancel from '../assets/cancel.svg';
import {useDispatch} from 'react-redux';
import {useColumnStore} from '../components/foods-list/ColumnOrder';
import {proParseFloat} from './dtjs/parsers';

export const CELL_HEIGHT = 30;

const getValue = val => {
  return val ? val.weights : 0;
};

export const getFoodName = (item, needID) => getHunName(item) ?? getEngName(item) ?? getFirstLangAvailable(item) + (needID?`(${item.id})`:'');
export const getFoodLang = (item, lang) => item?.localisations?.[lang]?.[0];
export const getHunName = item => item?.localisations?.hu?.[0];
export const getEngName = item => item?.localisations?.en?.[0];
export const getGerName = item => item?.localisations?.de?.[0];
export const getFirstLangAvailable = item => item?.localisations?.[Object.keys(item?.localisations).length > 0 ? Object.keys(item?.localisations)[0] : null]?.[0];
export const getFrequency = item => item?.frequency;
export const getContain = (item, key) => item?.details?.[key];

export const getFixedDict = dict => {
  return _.mapValues(dict, o => {
    console.log('o', o, typeof o);
    return o.toFixed(2);
  });
};
export const getFoodValidation = food => {
  return food.selected ? food.selected.good : UNVALIDATED;
};
export const getFormattedDict = dict => {
  return JSON.stringify(_.mapValues(dict, o => o.toFixed(2)));
};

export const getDiffrelative = (item, refItem) => {
  const res = getDetails(item);
  const res2 = getDetailsPro(refItem);
  const filtered_keys = _.filter(_.keys(res2), k => res2[k] !== 0);
  return _.transform(
    filtered_keys,
    (result, key) => {
      return (result[key] = res[key] / res2[key]);
    },
    {},
  );
};
export const getCh = item =>
  item
    ? item.carbohydrate ?? item.carbohydrates ?? undefined
    : undefined;
export const getProtein = item =>
  item ? (item.protein ?? item.proteins ?? undefined) : undefined;
export const getFat = item =>
  item ? (item.fat ?? item.fats ?? undefined) : undefined;
export const getDiff = (item, refItem) => {
  const res = getDetails(item);
  const res2 = getDetailsPro(refItem);
  return _.transform(
    res,
    (result, value, key) => {
      return (result[key] = res[key] - res2[key]);
    },
    {},
  );
};

export const getCompareFormatted = (item, refItem) => {
  const res = getDiff(item, refItem);
  return getFormattedDict(res);
};

export const CARBOHYDRATE = 'carbohydrate';
export const FAT = 'fat';
export const PROTEIN = 'protein';
export const CALORIE = 'calorie';

export const getDetails = item => {
  // if (item) {
  //     console.log(item.details, item.details.alcohols, item.details.calories,
  //         item.details.caffeines, item.details.carbohydrates, item.details.fats,
  //         item.details.nationality, item.details.proteins, item.details.sodiums, item.details['vita-cs'], item.details.waters );
  // }
  if (item && item.details) {
    return {
      alcohols: getValue(item.details.alcohols),
      calories: getValue(item.details.calories),
      caffeines: getValue(item.details.caffeines),
      carbohydrate: getValue(item.details.carbohydrate),
      fats: getValue(item.details.fats),
      nationality: item.details.nationality,
      proteins: getValue(item.details.proteins),
      sodiums: getValue(item.details.sodiums),
      'vita-cs': getValue(item.details['vita-cs']),
      waters: getValue(item.details.waters),
    };
  }
  return {};
};
const getDetailsPro = _.memoize(getDetails);
const LectorState = React.memo(({id}) => {
  const res = useFoodDbStore(({lectorState}) => {
    return lectorState[id] ?? {status: null};
  });
  // console.log('res', res);
  return res?.status === true ? '✓' : res?.status === false ? '✖' : '';
});
const isUndefOrNan = val => val === undefined || val !== val;
const columnStdGen = (key) => (
  {
    getter: item => getContain(item, key),
    setter: (itemId, dispatch) => e => {
      let value = e.target.value === '' ? null : proParseFloat(e.target.value);
      dispatch(changeFoodContains(itemId, key, value));
    },
    sortFn:
      (b, a) => {
        const av = getContain(a, key);
        const bv = getContain(b, key);
        if (isUndefOrNan(av) && isUndefOrNan(bv)) {
          if (av !== av) {
            return 1;
          }
          if (bv !== bv) {
            return -1;
          }
          return 0;
        }
        if (isUndefOrNan(av)) return -1;
        if (isUndefOrNan(bv)) return 1;
        return av - bv;
      },
    style: {maxWidth: 50},
  });
const normalizeDate = o => o < 2000000000 ? o * 1000 : o;
const arrayFieldComparator = (a, b, field) => a.hasOwnProperty(field) && b.hasOwnProperty(field) && _.size(a[field]) && _.size(b[field]) ? _.size(b[field]) - _.size(a[field]) :
  a.hasOwnProperty(field) && _.size(a[field]) && !b.hasOwnProperty(field) ? b.hasOwnProperty(field) && _.size(b[field]) && !a.hasOwnProperty(field) ? 1 : -1 : null;
const nameCreator = lang => ({
  [lang.toUpperCase()]: {
    name: lang.toUpperCase(),
    getter: item => getFoodLang(item, lang),
    setter: (itemId, dispatch) => e => dispatch(changeFoodName(itemId, e.target.value, lang)),
    sortFn: (b, a) => ('' + getFoodLang(a, lang)).localeCompare(getFoodLang(b, lang)),
    editable: true,
  },
});
export const langs = ['en', 'hu', 'de', 'it', 'fr',
  'pt', 'pl', 'tr', 'es', 'ro',
  'se', 'no', 'nl', 'sv'];
export const column_selector_fns = {
  ID: {
    name: 'ID',
    getter: item => item.id,
    setter: (itemId, dispatch) => e => null,
    style: {overflow: 'hidden'},
    sortFn: (b, a) => a.id - b.id,
  },
  Calorie: {
    name: 'Calorie',
    ...columnStdGen('calorie'),
    editable: true,
  },
  Carb: {
    name: 'Carb',
    ...columnStdGen('carbohydrate'),
    editable: true,
  },
  Fat: {
    name: 'Fat',
    ...columnStdGen('fat'),
    editable: true,
  },
  Protein: {
    name: 'Protein',
    ...columnStdGen('protein'),
    editable: true,
  },
  Fiber: {
    name: 'Fiber',
    ...columnStdGen('fiber'),
    editable: true,
  },
  GI: {
    name: 'GI',
    ...columnStdGen('gi'),
    editable: true,
  },
  Frequency: {
    name: 'Freq.',
    getter: item => getFrequency(item, ''),
    setter: (itemId, dispatch) => e => null,
    style: {maxWidth: 50},
    sortFn: (b, a) =>
      (a.frequency ? a.frequency : 0) - (b.frequency ? b.frequency : 0),
  },
  Uid: {
    name: 'Uid',
    getter: item => item.uid,
    style: {maxWidth: 150, overflow: 'hidden'},
    sortFn: (b, a) => ('' + a.uid).localeCompare(b.uid),
  },
  Source: {
    name: 'Source',
    getter: item => item.src,
    setter: (itemId, dispatch) => e => dispatch(changeFoodSrc(itemId, e.target.value)),
    style: {maxWidth: 50, overflow: 'hidden'},
    sortFn: (b, a) => ('' + a.src).localeCompare(b.src),
    editable: true,
  },
  Modification: {
    name: 'Modification',
    getter: item => `${format(new Date(normalizeDate(item.m)), 'MM.dd HH:mm')}`,//new Date(item.m),
    style: {maxWidth: 100, overflow: 'hidden'},
    sortFn: (b, a) => (a.m ? a.m : 0) - (b.m ? b.m : 0),
  },
  Created: {
    name: 'Created',
    getter: item => `${item.created_at ? format(new Date(normalizeDate(item.created_at)), 'yyyy.MM.dd HH:mm') : 'NA'}`,//new Date(item.m),
    style: {maxWidth: 150, overflow: 'hidden'},
    sortFn: (b, a) => (a.created_at ? a.created_at : 0) - (b.created_at ? b.created_at : 0),
  },
  SelfLector: {
    name: 'Anna',
    getValue: item => useFoodDbStore.getState()?.lectorState[item.id]?.status ?? null,
    getter: item => {
      return <LectorState id={item.id}/>;
    },
    setter: (itemId, dispatch) => e => null,
    options: [true, false, null],
    style: {maxWidth: 50},
    sortFn: (b, a) => (a.frequency ? a.frequency : 0) - (b.frequency ? b.frequency : 0),
  },
  USDA_ID: {
    name: 'USDA_ID',
    getter: item => item.USDA_ID,
    style: {maxWidth: 150, overflow: 'hidden'},
    sortFn: (b, a) => (a.USDA_ID ? a.USDA_ID : 0) - (b.USDA_ID ? b.USDA_ID : 0),
  },
  Lector: {
    name: 'Status',
    getValue: item => item.lectored,
    getter: item => <img
      style={{height: 24, width: 24, marginLeft: 8}}
      src={lectoredMap[item.lectored]}
      title={lectoredNameMap[item.lectored]}
      alt={''}
    />,
    valueRender: lector => <img
      style={{height: 24, width: 24, marginLeft: 8}}
      src={lectoredMap[lector]}
      title={lectoredNameMap[lector]}
      alt={''}
    />,
    setter: (itemId, dispatch) => e => null,
    options: Object.keys(lectoredMap),
    style: {maxWidth: 50},
    sortFn: (b, a) => lectorOrder[a.lectored] - lectorOrder[b.lectored],
    onPress: (item, dispatch) => item.lectored in lectorsNext && dispatch(changeFoodLectored(item.id, lectorsNext[item.lectored])),
  },
  Extra: {
    name: 'Extra',
    getValue: item => item,
    getter: item => <ExtraContentRender item={item}/>,
    valueRender: item => <ExtraContentRender item={item}/>,
    sortFn: (b, a) => {
      const recipeScore = arrayFieldComparator(a, b, 'recipe');
      const commentsScore = arrayFieldComparator(a, b, 'comments');
      return a.lectored === 'DELETED' ? 1 : b.lectored === 'DELETED' ? -1 : recipeScore ?? commentsScore ?? 0;
    },
    style: {
      maxWidth: 100,
      justifyContent: 'flex-end',
    },
  },
  ...Object.assign({}, ...langs.map(o => nameCreator(o))),
};

const addThumbToUrl = url => {
  const urlSplit = decodeURIComponent(url).split('/');
  const [urlFirstHalf, urlEnd] = [urlSplit.slice(0, -1), _.last(urlSplit)];
  const thumbnail = urlFirstHalf.join('/') + '%2Fthumb%4096_' + urlEnd;
  // console.log('Thumbnail url:', thumbnail);
  return thumbnail;
};
const ExtraContentRender = ({item}) => {
  const dispatch = useDispatch();
  const imgSource = useMemo(
    () => ({
      uri: item?.others && item.uid !== 'FOODTREND_ID' ? addThumbToUrl(item.others.img) : `https://app.diabtrend.com/assets/food/${item.id}.jpg`,
      cache: 'web',
    }),
    [item?.id],
  );
  // const [imgSource, setImgSrc] = useState(
  //   `https://app.diabtrend.com/assets/food/${item?.id}.jpg`,
  // );
  const [imgErr, setImgErr] = useState(false);

  const {selectedColumns} = useColumnStore();
  const onDeletePress = () => {
    dispatch(deleteFoodKaloriID(item.id));
    // this.props.setHunName(itemId, new_name);
  };

  return <div
    style={{
      ...styles.defaultStyle,
      justifyContent: 'flex-end',
      minWidth: 100,
    }}
  >
    {item.comments && item.comments.length > 0 && (
      <Comment
        style={{height: 24, width: 24, marginLeft: 8}}
        title="Comment"
        alt={''}
      />
    )}
    {item.recipe && _.size(item.recipe) > 0 && (
      <Chef
        style={{height: 24, width: 24, marginLeft: 8}}
        fill="orange"
        title="Recipe"
        alt={''}
      />
    )}
    {imgSource && selectedColumns.includes('Image') && !imgErr && (
      <img
        style={{height: 32, width: 32, marginLeft: 8}}
        src={imgSource.uri}
        title="Preview"
        alt={''}
        onError={() => setImgErr(true)}
      />
    )}
    {item.lectored !== 'DELETED' && (
      <img
        style={{
          height: 24,
          width: 24,
          marginLeft: 8,
          cursor: 'pointer',
        }}
        alt={''}
        src={Cancel}
        title="Delete"
        onClick={() => {
          areYouSureToDelete(onDeletePress, item);
        }}
      />
    )}
  </div>;
};

const areYouSureToDelete = (onDeletePress, item) => {
  const hunName = getHunName(item, EMPTY_STR);
  const engName = getEngName(item, EMPTY_STR);
  const r = window.confirm(
    'Are you sure you want to delete?\n' + hunName ? hunName : engName ? engName : '',
  );
  if (r === true) {
    onDeletePress();
  }
};
const styles = {
  defaultStyle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
};
export const all_columns = [...Object.keys(column_selector_fns), 'Image'];
