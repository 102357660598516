import fuzzysort from 'fuzzysort';
import unorm from 'unorm';
import _ from 'lodash';

// Accent character removal code:
export const replaceAccentedChars = str =>
  unorm
    .nfd(str)
    .replace(/[\u0300-\u036f]/g, '')
    // .replace(/\./g, '_')
    .replace(/, /g, ' ')
    .toLowerCase();
// export const replaceAccentedChars = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

export const MAX_RESULT_LIMIT = 5000;
const SCORE_THRESHOLD = -100000;
export const fuzzySort = (phrase, targets, keyName) => {
  const options = {
    limit: MAX_RESULT_LIMIT, // don't return more results than you need!
    allowTypo: false, // if you don't care about allowing typos
    threshold: SCORE_THRESHOLD + 1, // we don't know what is the lowest score, but just return all...
    keys: keyName,
    scoreFn: a => {
      // if (['101004834'].includes(a.obj.id)) {
      //   console.log('a', a);
      // }
      const sc = Math.max(
        a[0] ? a[0].score : SCORE_THRESHOLD,
        a[1] ? a[1].score : SCORE_THRESHOLD,
        a[2] ? a[2].score : SCORE_THRESHOLD,
      );
      return sc;
    },
  };
  const words = phrase.split(' ');
  // const words = [phrase];
  const wordsMinLen2 = words.filter(str => str.length > 1);
  const searchScoreResults = searchByWords(wordsMinLen2, options, targets);
  return searchScoreResults;
};

const getF1Score = (arr, size) => {
  if (arr.some(v => v.score < -250)) {
    return 0;
  }
  return (
    size /
    (arr
        .map(v => (v.score + 200) / 300)
        .reduce((acc, v) => acc + 1 / (v > 0.01 ? v : 0.01), 0) +
      (size - arr.length) * 120)
  );
};

// [arr[0].obj.name0, arr[0].obj.name1, arr[0].obj.name2]
//   .filter(o => o && o !== '')
//   .map(v => (v.score + 100) / 100)
//   .reduce((acc, v) => acc + 1 / (v > 0.01 ? v : 0.01), 0);
const getIndexOfLastMax = array => array.reduce((r, v, i, a) => v > a[r] ? i : r, 0);
const getIndexOfLastMin = array => array.reduce((r, v, i, a) => v < a[r] ? i : r, 0);
const getUnwrapped = o => (o ? {indexes: [...o.indexes]} : null);
const searchByWords = (words, options, targets) => {
  const timer = new Date().getTime();
  // console.log(
  //   'words.map(replaceAccentedChars)',
  //   words.map(replaceAccentedChars),
  // );
  // console.log('targets', targets);
  const wordsResults = words.map(replaceAccentedChars).map((word, i) =>
    fuzzy(word, targets, options, i).map(o => ({
      0: getUnwrapped(o[0]),
      1: getUnwrapped(o[1]),
      2: getUnwrapped(o[2]),
      obj: o.obj,
      score: o.score,
    })),
  );
  // console.log('wordsResults', wordsResults.map(res => res.length));
  const size = words.length;
  // console.log('words', words.map(replaceAccentedChars));
  // console.log('timer', new Date().getTime() - timer);
  // console.log(
  //   'wordResults',
  //   wordsResults.map(o => o.map(o2 => o2.obj)),
  //   wordsResults,
  // );
  const SPACE_BEFORE_NEGSCORE = -25;
  const SPACE_AFTER_NEGSCORE = -20;
  const ONE_MISSED_CHARS = -30;
  const NOT_EXACT_MATCH = -30; // puffasztot rizs, alma első találatok ezzel már
  const MULTIPLE_MISSED_CHARS = -250;
  const isReactWeb = true || (window.navigator && window.navigator.platform); // TODO why the web solution works well on "yogurt" on many duplicated synonim foodnames?
  // console.log('isReactWeb', isReactWeb);
  // console.log('wordsResults', wordsResults);
  // console.log('words', words);
  const allWordsLength = words.join(' ').length;
  const wordDict = wordsResults.reduce((acc, results, j) => {
    const word = words[j];
    results.map((o, i) => {
      const firstKeyName = o.obj.name0 ? o.obj.name0.target : '';
      const secondKeyName = o.obj.name1 ? o.obj.name1.target : '';
      const thirdKeyName = o.obj.name2 ? o.obj.name2.target : '';
      const firstKeyNameLen = firstKeyName.length + 1;
      const secondKeyNameLen = secondKeyName.length;
      const thirdKeyNameLen = thirdKeyName.length;
      const getScoreForOneSinonym = (exp, keyname) => {
        if (!exp || !exp.indexes) {
          return -keyname.length + SPACE_BEFORE_NEGSCORE +
            SPACE_AFTER_NEGSCORE + NOT_EXACT_MATCH +
            MULTIPLE_MISSED_CHARS + o.obj.frequency;
        }
        // const found = o[0]?.indexes ? o[0] : o[1]?.indexes ? o[1] : o[2]?.indexes ? o[2] : null;

        let firstChar, lastChar;
        firstChar = exp.indexes[0];
        lastChar = exp.indexes[exp.indexes.length - 1];
        const beforeScore =
          firstChar > 0 && keyname[firstChar - 1] !== ' '
            ? SPACE_BEFORE_NEGSCORE
            : 0;
        const afterScore =
          lastChar < keyname.length - 1 &&
          keyname[lastChar + 1] !== ' '
            ? SPACE_AFTER_NEGSCORE
            : 0;
        const exactMatch = keyname.length === allWordsLength
          ? 0
          : NOT_EXACT_MATCH;
        const missedlettersScore =
          exp.indexes.length < lastChar - firstChar // Means multiple missed characters
            ? MULTIPLE_MISSED_CHARS
            : exp.indexes.length === lastChar - firstChar // Means one missed character
              ? ONE_MISSED_CHARS
              : 0;

        // if (exactMatch === 0) {
        //   console.log('exactMatch', exactMatch, o);
        // }
        // we don't use o.score :'D
        const score = 0 - keyname.length + beforeScore + afterScore + exactMatch + missedlettersScore + o.obj.frequency;
        if (['101003494'].includes(o.obj.id)) {
          console.log('missedlettersScore', {
            id: o.obj.id,
            keynamelen: keyname.length,
            missedlettersScore, afterScore,
            beforeScore, score, firstKeyNameLen, exactMatch,
            secondKeyNameLen, thirdKeyNameLen,
            firstChar, lastChar, o,
            frequency: o.obj.frequency,
            indexes: exp.indexes,
            exp,
          });
        }
        return score;
      };
      const scores = [getScoreForOneSinonym(o[0], firstKeyName), getScoreForOneSinonym(o[1], secondKeyName), getScoreForOneSinonym(o[2], thirdKeyName)];
      if (['101003494'].includes(o.obj.id)) {
        console.log('scores', scores, getIndexOfLastMax(scores));
      }
      const scoreIdx = scores.length > 1 ? getIndexOfLastMax(scores) : 0;
      const mergedIndexes = [].concat(
        o[0] ? o[0].indexes : [],
        o[1] ? o[1].indexes.map(o => o + firstKeyNameLen) : [],
        o[2] ? o[2].indexes.map(o => o + firstKeyNameLen + secondKeyNameLen) : [],
      );
      const newObj = {
        indexes: mergedIndexes,
        obj: o.obj,
        score: scores[scoreIdx],
        word: isReactWeb ? word : null,
      };

      const key = o.obj.id;

      '101004345' === key && console.log('key', o);
      '101001573' === key && console.log('key', o);
      '65qk6gB5bqVwZKZXf1DO' === key && console.log('key', o);
      if (isReactWeb) {
        // This is for web. in react-native it is undefined
        if (key in acc) {
          if (!acc[key].find(item => item.word === word)) {
            acc[key].push(newObj);
          }
        } else {
          acc[key] = [newObj];
        }
      } else {
        key in acc ? acc[key].push(newObj) : (acc[key] = [newObj]);
      }
    });
    return acc;
  }, {});
  // console.log('worldDict', wordDict);
  // console.log('searched', wordDict['psWx5dJtqU5BFSZ08Ly4']);
  // console.log('searched', wordDict['AF6RgzMt41RL6YtOMvvo']);
  // console.log('size', size);
  const scoresArr = Object.entries(wordDict).map(([k, v]) => [
    v[0],
    getF1Score(v, size),
    [].concat(...v.map(o => o.indexes)).sort((a, b) => a - b),
  ]);
  // console.log('scoresArr', scoresArr);
  // console.log(
  //   'scoresArr',
  //   scoresArr.filter(o => ['101002855', '101004834'].includes(o[0].obj.id)),
  // );
  // console.log('sortedScores', scoresArr.sort((a, b) => b[1] - a[1]));

  const sortedScores = scoresArr
    .filter(o => o[1] > 0.017)
    .sort((a, b) => b[1] - a[1]);
  // console.log('sortedScores', sortedScores);

  const res = sortedScores.map(o => ({...o[0], indexes: o[2]}));
  // console.log('res', res);
  return res;
};

let fuzzySorter = null;
const getFuzzySorterInstance = options => {
  if (fuzzySorter === null) fuzzySorter = fuzzysort.new(options);
  return fuzzySorter;
};
const fuzzy = (phrase, targets, options, i) => {
  const sorter = getFuzzySorterInstance(options);
  return sorter.go(phrase, targets, options);
};
