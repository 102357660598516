export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

export const showPopup = (content, onClose) => (dispatch, getState) => {
  dispatch({
    type: SHOW_POPUP,
    content: content,
    onClose: onClose,
  });
};

export const closePopup = () => (dispatch, getState) => {
  dispatch({
    type: HIDE_POPUP,
  });
};
