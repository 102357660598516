import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import _ from 'lodash';

const ControlledInput = React.memo((props) => {
  const {value, onChange, style, ...rest} = props;
  const [text, setText] = useState(value);
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, text]);
  const onChangeDebounce = useCallback(_.debounce((...arg) => onChange && onChange(...arg), 500), []);

  const handleChange = useCallback((e) => {
    setCursor(e.target.selectionStart);
    setText(e.target.value);
    onChangeDebounce(e);
  }, [onChangeDebounce]);
  const mergedStyle = useMemo(() => ({
    borderWidth: 0,
    ...style,
  }), [style]);

  return <input ref={ref} value={text} onChange={handleChange} style={mergedStyle}  {...rest} />;
});

export const ControlledTextarea = (props) => {
  const {value, onChange, ...rest} = props;
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };

  return <textarea ref={ref} value={value} onChange={handleChange} {...rest} />;
};
export default ControlledInput;
