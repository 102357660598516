import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {FixedSizeList as List} from 'react-window';
import './FoodRow.css';
import {selectFoodID, toggleFoodID} from '../../redux/actions';
import {CELL_HEIGHT, column_selector_fns} from '../../utils/food_helper';
import FoodRowKalor from './FoodRowKalor';
import {useFoodCacheDbStore, useFoodDbStore} from '../../redux/food_actions';
import {useWindowSize} from '../../utils/diamonds';
import {conditionTypes, useColumnStore} from './ColumnOrder';
import {DELETED, lectorOrder} from '../../utils/lector_helper';


const mapStateToProps = (state) => {
  const {food_id, food_compare, orderColumn, orderColumnDir} = state.selected;
  const {scrollToIndex, searchText} = state.controllers;
  return {orderColumn, orderColumnDir, food_id, food_compare, scrollToIndex, searchText};
};
const mapStateToPropsRow = ({selected}) => ({food_compare: selected.food_compare, food_id: selected.food_id});

const mapDispatchToProps = dispatch => ({
  selectRefFood: id => dispatch(selectFoodID(id)),
  toggleCompareFood: id => dispatch(toggleFoodID(id)),
  dispatch,
});
const Row = connect(
  mapStateToPropsRow,
  mapDispatchToProps,
)(({data, index, style, ...props}) => {
  const {selectRefFood, food_id, toggleCompareFood, food_compare, dispatch} = props;
  const item = data[index];

  return (
    <div key={`${item.id}`}>
      <div style={style} className="myrow">
        <FoodRowKalor
          item={item}
          index={index}
          selectRefFood={selectRefFood}
          food_id={food_id}
          toggleCompareFood={toggleCompareFood}
          compared={_.includes(food_compare, item.id)}
          dispatch={dispatch}
        />
      </div>
    </div>
  );
});
// const compareByKey = (a, b, key) => a[key] !== b[key]? a[key] > b[key]:
const FoodsListKalor = props => {
  const {width, height} = useWindowSize();
  const foodDb = useFoodDbStore(({foodDb}) => {
    return foodDb;
  });

  const {reverses, sortedColumns, filters} = useColumnStore(
    ({reverses, sortedColumns, filters}) => ({
      reverses,
      sortedColumns,
      filters,
    }),
  );
  const foodSearchResults = useFoodCacheDbStore(
    ({foodSearchResults}) => foodSearchResults,
  );
  // console.log('foodSearchResults:', foodSearchResults);
  // console.log('foodDb', foodDb && Object.values(foodDb).length);
  // const foodtrendDb = useFoodtrendUpdates(({ db }) => db);


  let orderedFoods = useMemo(() => {
    let foods = foodSearchResults?.length
      ? foodSearchResults.map(o => foodDb[o.ref.id])
      : Object.values(foodDb ? foodDb : {});
    foods = foods
      ? foods.map(o => ({
        ...o,
        lectorNum: lectorOrder[o.lectored],
      }))
      : [];
    if (!foodSearchResults?.length > 0) {
      foods = foods.sort((a, b) =>
        a?.gyakorisag - b?.gyakorisag
          ? b.gyakorisag - a.gyakorisag
          : b.comments && !a.comments && b.lectored !== DELETED
            ? 1
            : b.comments?.length !== a.comments?.length
              ? b.comments?.length - a.comments?.length
              : (b.lectorNum ? b.lectorNum : 0) - (a.lectorNum ? a.lectorNum : 0),
      );
    }

    foods = foods.sort((a, b) => {
      for (const [i, column] of sortedColumns.entries()) {
        const sortFn = column_selector_fns[column].sortFn;
        const res = reverses[i] ? sortFn(b, a) : sortFn(a, b);
        if (res !== 0) return res;
      }
      return 0;
    });
    const enabledFilters = {...filters};
    Object.entries(filters).map(([k, o]) => !o.enabled && delete enabledFilters[k]);
    foods = Object.keys(enabledFilters).length > 0 ? foods.filter(food => Object.entries(enabledFilters).every(([k, filter]) => {
      const getter = column_selector_fns[k].getValue ?? column_selector_fns[k].getter;
      const value = getter(food);
      // console.log('filter.conditionType ', filter.conditionType, !filter.valuesShown);
      const isConditionTrue = (!filter.conditionType || conditionTypes[filter.conditionType](value, filter.conditionValue));
      const isInShown = (filter.valuesShown === undefined || _.size(filter.valuesShown) === 0 || filter.valuesShown.hasOwnProperty(value));
      return isConditionTrue && isInShown;
    })) : foods;
    return foods;
  }, [foodSearchResults, foodDb !== null, reverses, sortedColumns, filters]);


  return (
    <List
      width={width}
      height={height - 116}
      itemData={orderedFoods}
      itemCount={orderedFoods.length}
      itemSize={CELL_HEIGHT}
    >
      {Row}
    </List>
  );
};


const FoodsListKalorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FoodsListKalor);
export default FoodsListKalorContainer;
