import React, {useState} from 'react';
import {CELL_HEIGHT, column_selector_fns, getEngName, getHunName} from '../../utils/food_helper';
import {updateFoodRecipe, deleteFoodKaloriID} from '../../redux/foodtrend_actions';
import {Button} from 'react-bootstrap';
import {ReactComponent as Comment} from '../../assets/comment.svg';
import {ReactComponent as Chef} from '../../assets/chef.svg';
import Cancel from '../../assets/cancel.svg';
import {EMPTY_STR} from '../../utils/utils';
import {TextColumn} from '../buildingblocks/TextCell';
import {useColumnStore} from './ColumnOrder';
import {useFoodDbStore} from '../../redux/food_actions';
import {GRAM} from '../../utils/enum_helper';
import _ from 'lodash';


const isGreenBg = item => Number(item.id) > 10000 || item.id[item.id.length - 4] === '-';
const FoodRowKalor = React.memo(props => {
  const {item, selectRefFood, food_id: refFoodId, dispatch, index} = props;

  const {selectedColumns} = useColumnStore();
  // console.log("item", item)
  // useEffect(() => {
  //   const url = `https://app.diabtrend.com/assets/food/${item?.id}.jpg`;
  //   fetch(url, { method: 'GET' })
  //     .then(res => {
  //       console.log('res', res);
  //       if (res.ok) {
  //         setImgSrc(url);
  //       }
  //     })
  //     .catch(err => console.log('ERROR'));
  //   return () => 0;
  // }, [item?.id]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          height: CELL_HEIGHT,
          justifyContent: 'space-around',
          backgroundColor:
            item.lectored === 'DELETED'
              ? '#ffe5d7'
              : isGreenBg(item)
                ? '#efffef'
                : 'white',
          borderBottom: 'solid',
          borderBottomColor: '#ddd',
          borderBottomWidth: 1,
        }}
      >
        {/*<Grid container spacing={24} style={{ backgroundColor: bgColor, paddingLeft: '8px', paddingRight: '8px' }}>*/}
        <div
          style={{
            ...styles.defaultStyle,
            flex: 0,
            minWidth: 56,
            justifyContent: 'flex-end',
          }}
        >
          {index}.
        </div>
        {selectedColumns.map(columnKey => {
          if (columnKey in column_selector_fns) {
            const {editable, onPress} = column_selector_fns[columnKey];
            return (
              <TextColumn
                key={`${columnKey}_${item.id}`}
                columnKey={columnKey}
                dispatch={dispatch}
                item={item}
                editable={editable}
                onPress={onPress}
              />
            );
          }
        })}
        <div
          style={{
            ...styles.defaultStyle,
            minWidth: 90,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AddRecipe
            refFoodId={refFoodId}
            item={item}
            dispatch={dispatch}
          />
          <Button
            style={{fontSize: 11}}
            variant={'outline-primary'}
            size={'sm'}
            onClick={() => {
              selectRefFood(item.id);
            }}
          >
            Select
          </Button>
        </div>

      </div>
    </div>
  );
});
const AddRecipe = (props) => {
  const {refFoodId, item, dispatch} = props;
  const {foodDb: foods} = useFoodDbStore(({foodDb}) => ({foodDb}));
  const food = foods?.[refFoodId];
  const recipe = food?.recipe ? food.recipe : {};
  return food && !(item.id in recipe) ? <Button
    variant={item.id in recipe ? 'link' : 'outline-primary'}
    style={{fontSize: 11, marginRight: 4}}
    size={'sm'}
    onClick={() => {
      if (item.id in recipe) {
      } else {
        dispatch(updateFoodRecipe(food.id, {id: item.id, amount: 1, weights: 1, unit: GRAM}));
      }
    }}
  >
    {item.id in recipe ? 'Remove recipe' : '+ Recipe'}
  </Button> : null;
};
const styles = {
  defaultStyle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
};

export default FoodRowKalor;
