import { searchableLists } from '../utils/pairings';
import firebaseFoodtrendDb from '../firebase/foodtrend';

export const SET_USDA_DB = 'SET_USDA_DB';
export const SET_FOOD_SEARCH_RESULT = 'SET_FOOD_SEARCH_RESULT';

export const requestFoodSearch = name => async (
  dispatch,
  getState,
  firebase,
) => {
  const state = getState();
  let [foods] = [null, null];
  if (!state.usda.db) {
    foods = await get_USDA_DB(false);
    foods = foods.concat(await get_USDA_DB(true));
    // console.log('foods SET', foods);
    dispatch({ type: SET_USDA_DB, foods });
  } else {
    foods = state.usda.db;
  }
  const results = searchableLists([name], foods)[0].res;
  // console.log('foods', foods)
  // console.log('res', results)
  dispatch({ type: SET_FOOD_SEARCH_RESULT, results });
};

const get_USDA_DB = isBig =>
  new Promise(resolve => {
    firebaseFoodtrendDb
      .storage()
      .ref(isBig ? 'usdaBranded.json' : 'usdaSTD.json')
      .getDownloadURL()
      .then(function(url) {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function(event) {
          var blob = xhr.response;
          // console.log('blob', blob);
          const reader = new FileReader();

          reader.addEventListener('loadend', e => {
            const text = e.srcElement.result;
            resolve(JSON.parse(text));
          });

          reader.readAsText(blob);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch(function(error) {
        // Handle any errors
      });
  });
