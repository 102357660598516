import fuzzysort from 'fuzzysort';
import _ from 'lodash';
import {replaceAccentedChars} from '../utils/fuzzy_helper';

export const FOOD_DELETED = 5;
const foodScoreLimits = [1, 3, 10, 30, 100, 300, 1000];
const getFoodFrequencyScore = ({frequency}) => {
  if (!frequency) {
    return 0;
  }
  let count = 0;
  for (let i of foodScoreLimits) {
    if (frequency < i) {
      return count;
    }
    count += 10;
  }
  return count;
};
export const postprocessFoodDbRaw = (set, get) => foodDb => {
  const {languages} = get();
  const isReactWeb = window.navigator && window.navigator.platform;
  const arrayOfNames = Object.values(foodDb)
    .filter(food => {
      return food.status !== FOOD_DELETED && food.localisations;
    })
    .map(food => {
      const allLocalisations = _.isArray(food.localisations)
        ? food.localisations
        : languages.map(l => food.localisations[l] ? food.localisations[l] : isReactWeb ? [food.id] : []).reduce(
          (acc, v) => [...acc, ...v],
          [],
        );
      return allLocalisations.map(name => {
        if (_.isArray(name)) {
          name = ' '.join(name); // TODO different from diabtrend.
        }
        const optimized = name
          ? [name]
            // .split(',') // TODO it is included in diabtrend, remove "[]" around name too.
            .map(replaceAccentedChars)
            .map(fuzzysort.prepare)
          : [''];
        const optimized2 = optimized.length > 2 ? optimized[2] : '';
        // if (food.id === '9ue8OxFCBFZNTXfLE94J') {
        //   console.log('food.id', food.id, fuzzysort.prepare(food.id));
        // }
        const result = {
          name0: optimized[0],
          name1: optimized.length > 1 ? optimized[1] : '',
          name2: isReactWeb
            ? fuzzysort.prepare(`${food.id} ${optimized2 !== '' ? optimized2.target : ''}`)
            : optimized2,
          id: food.id,
          frequency: getFoodFrequencyScore(food),
        };
        return result;
      });
    });
  const optimal = [].concat(...arrayOfNames);
  console.log('optimal', optimal.length);
  set(s => ({
    ...s,
    // timestamp: action.timestamp,
    // version: action.version,
    // lang: action.lang,
    optimal: optimal,
  }));
};
