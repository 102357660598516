import React, {useState} from 'react';


const Hovered = (props) => {
  const [state, setState] = useState({hovered: false});
  const handleMouseLeave = () => {
    setState({hovered: false});
  };
  const handleMouseEnter = () => {
    setState({hovered: true});
  };
  const {hoverStyle, children} = props;
  return (
    <div
      style={{
        ...(state.hovered ? hoverStyle : {}),
        cursor: 'pointer',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default Hovered;
