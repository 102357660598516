import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import ContainsLine from '../comparation/ContainsLine';
import Input from '@material-ui/core/Input';
import {changeFoodLectored, changeFoodName, changeFoodServings, changeFoodSrc, deleteFoodComment, toggleFoodTag} from '../../redux/foodtrend_actions';
import {darkBackground, background, green, secondaryGreen, darkerBackground} from '../../utils/colors_helper';
import {Button} from 'react-bootstrap';
import {closePopup, showPopup} from '../../redux/popup_actions';
import {unitEnumToName} from '../../utils/enum_helper';
import Hovered from '../Hovered';
import {lectoredMap, lectoredNameMap} from '../../utils/assets_helper';
import _ from 'lodash';
import {useFoodDbStore} from '../../redux/food_actions';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import {getFoodName, langs} from '../../utils/food_helper';
import Recipes from './Recipes';
import ControlledInput from '../buildingblocks/ControlledInput';
import {proParseFloat} from '../../utils/dtjs/parsers';
import {
  DatePicker,
} from '@material-ui/pickers';
import {get_dynlink_data} from '../../utils/dynamicLinks_helpers';
import {blue} from '@material-ui/core/colors';

const LectoredSelector = ({onPress, lectored, food_id}) => {
  const {lectorState} = useFoodDbStore(({lectorState}) => ({
    lectorState: lectorState[food_id],
  }));
  useEffect(() => {
    useFoodDbStore.getState().getFoodLectorState(food_id);
    return () => 0;
  }, [food_id]);

  return (
    <div
      style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
    >
      <div>Unlectored</div>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 8,
          marginLeft: 8,
          marginRight: 8,
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        {Object.keys(lectoredNameMap).map((lectorOption, index) =>
          lectorOption === lectored ? (
            <img
              key={index}
              style={{height: 24, width: 24, marginLeft: 8, marginRight: 8}}
              src={lectoredMap[lectored]}
              title={lectoredNameMap[lectored]}
              alt={''}
            />
          ) : (
            <img
              key={index}
              style={{
                height: 24,
                width: 24,
                marginLeft: 8,
                marginRight: 8,
                opacity: 0.5,
                cursor: 'pointer',
              }}
              src={lectoredMap[lectorOption]}
              title={lectoredNameMap[lectorOption]}
              onClick={() => onPress(lectorOption)}
              alt={''}
            />
          ),
        )}
      </div>
      <div>Lectored</div>
      <Checkbox
        checked={lectorState?.status ?? false}
        color="primary"
        onChange={e => {
          useFoodDbStore
            .getState()
            .setFoodLectorState(food_id, e.target.checked);
        }}
      />
    </div>
  );
};
const tagOptions = {
  BREAKFAST: 1,
  LUNCH: 2,
  DINNER: 3,
  SNACK: 4,
  BRANDED: 5,
};
const Tags = ({dispatch, tags = [], id}) => {
  return Object.entries(tagOptions).map(([k, v]) =>
    <Button key={k} style={{margin: 4}} variant={(tags && tags.includes(v)) ? 'primary' : 'outline-dark'} size={'sm'}
            onClick={() => {
              dispatch(toggleFoodTag(id, v));
            }}
    >{k}</Button>,
  );
};
const Servings = ({dispatch, values, id, onMetricsChange}) => {
  const valuesUnits =
    values && _.isArray(values) ? values.map(o => o.value.toUpperCase()) : [];
  const ServingPopup = () => (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column', maxHeight: 600, width: 400}}>
        {Object.entries(unitEnumToName)
          .filter(([unitKey, unitName]) => !valuesUnits.includes(unitKey))
          .sort(([k1, a], [k2, b]) => a.localeCompare(b))
          .map(([unitKey, unitName], index) => {
            return (
              <div
                onClick={() => {
                  onMetricsChange(id, unitKey, 0);
                }}
                style={{padding: 4}}
                key={unitKey}
              >
                <Hovered
                  hoverStyle={{
                    paddingHorizontal: 4,
                    backgroundColor: '#BCDDF3',
                    borderRadius: 4,
                  }}
                >
                  {unitName}
                </Hovered>
              </div>
            );
          })}
      </div>
      <Button onClick={() => dispatch(closePopup())}>OK</Button>
    </div>
  );
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 12,
        alignItems: 'flex-start',
      }}
    >
      {values && _.isArray(values) &&
        values.map((val, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 12,
            }}
          >
            <div style={{marginRight: 12}}>
              {val.value.toUpperCase() in unitEnumToName
                ? unitEnumToName[val.value.toUpperCase()]
                : val.value + ' (ismeretlen mértékegység, kellene?)'}
              :
            </div>
            <input
              style={styles.inputStyle}
              onChange={e => {
                onMetricsChange(id, val.value, e.target.value);
                dispatch(
                  changeFoodServings(
                    id,
                    val.value,
                    proParseFloat(e.target.value),
                  ),
                );
              }}
              value={val.weights}
              placeholder={'...'}
              disableUnderline={true}
            />
            <div style={{marginRight: 12}}>g</div>
            <button
              style={{
                marginLeft: 8,
                height: 28,
                width: 28,
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: 'transparent',
                borderRadius: 16,
                backgroundColor: 'white',
              }}
              onClick={() => {
                onMetricsChange(id, val.value, null);
                dispatch(changeFoodServings(id, val.value, null));
              }}
            >
              X
            </button>
          </div>
        ))}

      <Button
        onClick={() => {
          dispatch(showPopup(ServingPopup, null));
        }}
      >
        Add other serving
      </Button>
    </div>
  );
};
const InputEditors = ({lang, refFood, dispatch, ...props}) => {
  const {localisations, id} = refFood ? refFood : {};
  // console.log('localisations', localisations);
  const onChange = useCallback(
    (e, idx) => dispatch(changeFoodName(id, e.target.value, lang, idx)),
    [lang, id],
  );
  return (
    <div>
      {localisations[lang] &&
        localisations[lang].map((value, i) => (
          <div style={{flex: 1}}>
            <InputEditor
              key={`${i}_${id}`}
              style={{backgroundColor: darkBackground}}
              {...props}
              value={value}
              onChange={e => onChange(e, i)}
            />
          </div>
        ))}
      <button
        style={{
          height: 28,
          marginHorizontal: 100,
          alignSelf: 'center',
          marginBottom: 8,
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: 'transparent',
          backgroundColor: 'transparent',
        }}
        onClick={e =>
          onChange(
            {target: {value: ''}},
            localisations[lang] ? localisations[lang].length : 0,
          )
        }
      >
        + {lang.toUpperCase()}
      </button>
    </div>
  );
};
const InputEditor = ({onChange, value, style, ...props}) => {
  const onChangeThrottled = useCallback(
    _.throttle(onChange, 800, {leading: false, trailing: true}),
    [onChange],
  );
  const [valueEdit, setValueEdit] = useState(value);
  return (
    <div style={{paddingLeft: 8, paddingRight: 8}}>
      <input
        style={{
          ...styles.inputStyle,
          ...style,
          width: '100%',
        }}
        {...props}
        value={valueEdit}
        onChange={e => {
          setValueEdit(e.target.value);
          onChangeThrottled(e);
        }}
      />
      {/*The hidden placeholder hack, to make the <input /> resize on typing.*/}
      <div style={{visibility: 'hidden', paddingLeft: 6, paddingRight: 6}}>{valueEdit}</div>
    </div>
  );
};
const ReferenceFood = React.memo(props => {
  const search = [];
  const {dispatch, food_id} = props;
  const {foodDb: foods} = useFoodDbStore(({foodDb}) => ({foodDb}));
  const refFood = foods?.[food_id];

  const [comment, setComment] = useState('');
  const metrics = useMemo(() => refFood?.metrics, [refFood?.metrics]);
  // componentWillReceiveProps(nextProps) {
  //   const { refFood } = nextProps;
  //   if (refFood !== this.props.refFood) {
  //     // this.state = {
  //     //   metrics: refFood && refFood.metrics ? refFood.metrics : [],
  //     // };
  //     console.log('this.state', this.state.metrics);
  //   }
  // }
  const {id, comments} = refFood ? refFood : {};
  const onMetricsChange = (id, key, weights) => {
    dispatch(changeFoodServings(id, key, weights));
  };
  console.log('Food details:', refFood);

  return refFood ? (
    <div
      key={id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: darkBackground,
        position: 'absolute',
        bottom: 0,
        width: '100%',
        maxHeight: '60%',
      }}
    >
      <div
        style={{
          flexDirection: 'row',
          backgroundColor: darkerBackground,
          width: '100%',
          display: 'flex',
          padding: 12,
          paddingLeft: 20,
          paddingRight: 20,
          alignItems: 'stretch',
          fontWeight: 'bold',
          cursor: 'pointer',
          color: 'blue',
        }}
        onClick={async () => {
          const url = await get_dynlink_data({
            foodid: food_id,
          }, {
            st: getFoodName(refFood),
            sd: 'A recept megtekintéséhez itt telepítheted az appot',
            ...(refFood.others?.img ? {si: refFood.others?.img} : {}),
          });
          alert('Food url created: ' + url);
          console.log('url', url);
        }}
      >
        {getFoodName(refFood) + ` (${id})`}
      </div>
      <div
        style={{
          flexDirection: 'row',
          width: '100%',
          display: 'flex',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 12,
          paddingBottom: 12,
          alignItems: 'stretch',
          overflowY: 'scroll',
        }}
      >
        <div style={{flex: 3, flexDirection: 'column'}}>
          <Section title={'Naming'}>
            <div style={{flex: 0, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
              {langs.map(lang => <InputEditors
                lang={lang}
                refFood={refFood}
                dispatch={dispatch}
                placeholder={lang}
              />)}
            </div>
          </Section><s></s>
          <Section title={'Nutritional values'}>
            <ContainsLine
              dispatch={dispatch}
              details={refFood.details}
              recipe={refFood.recipe}
              metrics={refFood.metrics}
              others={refFood.others}
              id={id}
              fluid={refFood.fluid}
            />
          </Section>
          <Section title={'Servings'}>
            <Servings
              dispatch={dispatch}
              values={metrics}
              id={id}
              onMetricsChange={onMetricsChange}
            />
          </Section>
          <Section title={'Tags'}>
            <Tags
              dispatch={dispatch}
              tags={refFood.tags}
              id={id}
            />
          </Section>
          <Section title={'Recipes'}>
            <Recipes
              id={food_id}
            />
          </Section>
          <Section title={'Comments'}>
            {comments &&
              comments.map((o, index) => (
                <div style={{display: 'flex'}} key={index}>
                  <div
                    style={{
                      paddingTop: 2,
                      paddingLeft: 8,
                      paddingRight: 8,
                      marginBottom: 4,
                      borderRadius: 8,
                      backgroundColor: 'white',
                    }}
                  >
                    {o.text}{' '}
                  </div>
                  <button
                    style={{
                      marginLeft: 8,
                      height: 28,
                      width: 28,
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderColor: 'transparent',
                      borderRadius: 16,
                      backgroundColor: 'white',
                    }}
                    onClick={() => dispatch(deleteFoodComment(id, o))}
                  >
                    X
                  </button>
                </div>
              ))}
            <Input
              multiline={true}
              style={{...styles.inputStyle, marginBottom: 12}}
              onChange={e => setComment(e.target.value)}
              value={comment}
              placeholder={'Comment...'}
              disableUnderline={true}
            />
          </Section>
          <Section title={'Others'}>
            <LectoredSelector
              onPress={state => dispatch(changeFoodLectored(id, state))}
              lectored={refFood.lectored}
              food_id={food_id}
            />
            <SourceSelector
              onPress={state => dispatch(changeFoodSrc(id, state))}
              item={refFood}
            />
          </Section>
        </div>
      </div>
      {/*<SimilarItems />*/}
    </div>
  ) : null;
});
const Section = ({title, onPress, children}) => <>
  <div style={{
    fontSize: 17, fontWeight: 'bold', paddingTop: 8, paddingBottom: 8,
    cursor: onPress ? 'pointer' : 'auto',
  }} onClick={onPress}>
    {title}
  </div>
  <div style={{backgroundColor: 'white', borderRadius: 20, padding: 12, marginBottom: 16}}>
    {children}
  </div>
</>;

const possibleSources = ['UK', 'SV', 'IT', 'FR', 'DT', ''];
const SourceSelector = ({item, onPress}) => {
  const [src, setSrc] = useState(item?.src);
  // 101004163
  return <div style={{flexDirection: 'row', display: 'flex', marginTop: 8}}>
    <div style={{flexDirection: 'row', display: 'flex', backgroundColor: 'white', borderRadius: 8}}>
      {possibleSources.map(s => <div
        style={{minWidth: 30, fontWeight: src === s ? 'bold' : 'thin', padding: 8, color: src === s ? 'blue' : 'black', cursor: 'pointer'}}
        onClick={() => {
          setSrc(s);
          onPress(s !== '' ? s : null);
        }}>
        {s !== '' ? s : 'None'}
      </div>)}
    </div>
  </div>;
};

const styles = {
  inputStyle: {
    backgroundColor: darkBackground,
    paddingLeft: 8,
    marginRight: 12,
    borderWidth: 0,
  },
};

const mapStateToProps = ({selected}) => ({
  food_id: selected.food_id,
});

const mapDispatchToProps = dispatch => ({dispatch});

const ReferenceFoodContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferenceFood);
export default ReferenceFoodContainer;
