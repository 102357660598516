import _ from 'lodash';

export const SET_PHOTOS = 'SET_PHOTOS';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';

// export const initFirebase = () => (dispatch, getState, firebase) => {
//   app.auth().onAuthStateChanged(user => {
//     if (user) {
//       console.info('[foodtrend] Uid:', user.uid, 'User:', user);
//       dispatch(getPhotos());
//       dispatch(getFoods());
//     } else {
//       console.info('[foodtrend] User: null');
//     }
//   });
//   app.auth().signInWithEmailAndPassword('havliktomi@gmail.com', 'Ttomi1991');
// };

export const updateStatusLog = (md5, update) => (
  dispatch,
  getState,
  firebase,
) => {
  const { user } = getState();
  firebase
    .database()
    .ref('/recoglogs/' + md5)
    .push({
      type: 'recogDelete',
      update,
      m: parseInt(new Date().getTime()),
      uid: user.food.uid,
    });
};
export const updateStatus = (md5, update) => (dispatch, getState, firebase) => {
  const path = `recogtrend/${md5}`;
  dispatch({ type: UPDATE_PHOTO, md5, update });
  firebase
    .database()
    .ref(path)
    .update(update)
    .catch(err => console.log('err', err));
  updateStatusLog(md5, update);
};
const sortByCreation = data => data.sort((a, b) => b.created - a.created);
const sortByFoodIds = data =>
  data.sort((a, b) =>
    a.food_ids[0] > b.food_ids[0]
      ? 1
      : a.food_ids[0] === b.food_ids[0]
      ? 0
      : -1,
  );

const getPhotosOrdered = _.memoize(photos => {
  let data = Object.entries(photos).map(([k, v]) =>
    Object.assign(v, {
      md5: k,
    }),
  );
  data = sortByCreation(data);
  return data
    .map((v, i) => (i % 2 === 1 ? [data[i - 1], data[i]] : null))
    .filter(o => o);
});
export const initRecogtrend = () => (dispatch, getState, firebase) => {
  firebase
    .database()
    .ref('/recogtrend/')
    .once('value', snap => {
      const data = getPhotosOrdered(snap.val());
      dispatch({
        type: SET_PHOTOS,
        photos: data,
      });
    })
    .catch(err => console.warn('ERROR', err));
  firebase
    .database()
    .ref('/recogtrend/')
    .on('child_changed', snap => {
      console.log('child_changed', snap.key, snap.val());
      const { photos } = getState();
      if (photos) {
        const samePhotos = photos.find(
          o => o[0].md5 === snap.key || o[1].md5 === snap.key,
        );
        const samePhoto =
          samePhotos[0].md5 === snap.key ? samePhotos[0] : samePhotos[1];
        if (samePhoto.m !== snap.val().m) {
          dispatch({
            type: UPDATE_PHOTO,
            md5: snap.key,
            update: snap.val(),
          });
        }
      }
    });
};
