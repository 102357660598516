import { SET_FOOD_SEARCH_RESULT, SET_USDA_DB } from './usda_actions';

export const usda_reducers = (state = {}, action) => {
  switch (action.type) {
    case SET_USDA_DB:
      return { db: action.foods };
    case SET_FOOD_SEARCH_RESULT:
      return {
        ...state,
        search: action.results,
      };
    default:
      return state;
  }
};
