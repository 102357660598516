import {
  CHANGE_FOOD,
  SCROLL_TO_INDEX,
  SEARCH_CHANGE,
  SELECT_FOOD_ID,
  SELECT_ORDER_COLUMN,
  SET_FOOD_USER,
  SET_FOODS,
  SET_GRAPH_USER,
  SWITCH_SCREEN,
  TOGGLE_COLUMN,
  TOGGLE_FOOD_ID,
  TOGGLE_MODAL_IS_OPEN,
} from './actions';
import _ from 'lodash';

export function user_reducers(state = {}, action) {
  switch (action.type) {
    case SET_FOOD_USER:
      return {
        ...state,
        food: action.user,
      };
    case SET_GRAPH_USER:
      return {
        ...state,
        dev: action.user,
      };
    default:
      return state;
  }
}
export function screen_reducers(state = 'FOODTREND', action) {
  switch (action.type) {
    case SWITCH_SCREEN:
      return state === 'FOODTREND' ? 'RECOGTREND' : 'FOODTREND';
    default:
      return state;
  }
}

export function foods_reducers(state = { data: {} }, action) {
  switch (action.type) {
    case SET_FOODS:
      return {
        ...action.foods,
      };
    case CHANGE_FOOD:
      return {
        ...state,
        ...action.food,
      };
    default:
      return state;
  }
}

export function selection_reducers(
  state = { columns: [], food_compare: [] },
  action,
) {
  switch (action.type) {
    case SELECT_ORDER_COLUMN:
      const direction =
        action.orderColumn !== null
          ? action.orderColumn === state.orderColumn
            ? state.orderColumn === 'desc'
              ? 'asc'
              : 'desc'
            : 'asc'
          : null;
      return {
        ...state,
        orderColumn: action.orderColumn,
        orderColumnDir: direction,
      };
    case TOGGLE_COLUMN:
      return {
        ...state,
        columns: _.xor(state.columns, [action.column]),
      };
    case SELECT_FOOD_ID:
      return {
        ...state,
        food_id: action.food_id,
      };
    case TOGGLE_FOOD_ID:
      return {
        ...state,
        food_compare: _.xor(state.food_compare, [action.food_id]),
      };
    case SEARCH_CHANGE:
      return {
        ...state,
        food_id: null,
      };
    default:
      return state;
  }
}

export function controller_reducers(
  state = { modalIsOpen: false, scrollToIndex: -1 },
  action,
) {
  switch (action.type) {
    case TOGGLE_MODAL_IS_OPEN:
      return {
        ...state,
        modalIsOpen: action.isOpen,
      };
    case SCROLL_TO_INDEX:
      return {
        ...state,
        scrollToIndex: action.scrollToIndex,
      };
    case SEARCH_CHANGE:
      return {
        ...state,
        searchText: action.text,
      };
    default:
      return state;
  }
}
