import {useFoodDbStore} from '../../redux/food_actions';
import {getRecipeContainWithDb, getRecipeFullWeight, getRecipeWeight} from './recipes_core';

export const getRecipeContain = (recipe, key) => {
  const {foodDb} = useFoodDbStore.getState();
  return getRecipeContainWithDb(recipe, key, foodDb);
};
export const getRecipeContain100g = (food, key) => {
  const {recipe} = food;
  if (key === 'gi') {
    return null;
  }
  const weight = getRecipeWeight(food);
  if (key === 'water') {
    const fullWeight = getRecipeFullWeight(food.recipe);
    return recipe ? (getRecipeContain(food, key) + (weight - fullWeight)) / weight * 100 : null;
  }
  return recipe ? getRecipeContain(recipe, key) / weight * 100 : null;
};
