import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import firebase from '../firebase/diabtrend-db';
import { foods_kalori_reducers } from './foodtrend_reducers';
import { popup_reducers } from './popup_reducers';
import { usda_reducers } from './usda_reducers';
import {
  controller_reducers,
  foods_reducers,
  screen_reducers,
  selection_reducers,
  user_reducers,
} from './reducers';
import { photos } from './photos_reducers';

const reduxReducers = combineReducers({
  selected: selection_reducers,
  user: user_reducers,
  foods: foods_reducers,
  kaloriFoods: foods_kalori_reducers,
  controllers: controller_reducers,
  popup: popup_reducers,
  usda: usda_reducers,
  photos: photos,
  screen: screen_reducers,
});

export const reduxStore = createStore(
  reduxReducers,
  applyMiddleware(thunk.withExtraArgument(firebase)),
);
