import React from "react";

export const Column = ({children, style}) => (
  <div
    style={{
      marginLeft: 8,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: 8,
      ...style,
    }}
  >
    {children}
  </div>
);
