import React from 'react';
import {Button, Col, Dropdown, Form} from 'react-bootstrap';
import {PopupContainer} from '../buildingblocks/PopupContainer';
import {useFoodCacheDbStore} from '../../redux/food_actions';
import {langs} from '../../utils/food_helper';
import _ from 'lodash';


export const LanguageMultiSelect = ({isOpen, onClose}) => {
  const {languages, setLanguages} = useFoodCacheDbStore(s => ({languages: s.languages, setLanguages: s.setLanguages}));
  return isOpen ? <MultiSelect values={languages} options={langs} setValues={setLanguages} onClose={onClose}/> : null;
};
//https://react-bootstrap.github.io/forms/overview/
export const MultiSelect = ({values, options, setValues, onClose}) => {
  const isAllSelected = options.every(o => values.includes(o));
  return <PopupContainer onClose={onClose}><Form style={{flexDirection: 'column'}}>
    <Form.Group className="mb-3">
      <Form.Label>Searchable languages:</Form.Label>
    </Form.Group>
    <Form.Group className="mb-3">
      <Form.Check
        style={{marginBottom: 12}}
        checked={isAllSelected}
        onChange={e => {
          if (isAllSelected) {
            setValues([]);
          } else {
            setValues(options);
          }
        }} label={'SELECT ALL'}/>
      {options.map(o => <Form.Check
        checked={values.includes(o)}
        onChange={e => {
          setValues(_.xor(values, [o]));
        }} label={o}/>)}
    </Form.Group>
    <Button onClick={onClose}>Ok</Button>
  </Form>
  </PopupContainer>;
};
