import STRING from './languages';

export const GRAM = 'GRAM';
export const unitEnumToName = {
  ML: STRING.mililiter,
  [GRAM]: STRING.gram,
  PIECE_SM: STRING.piece_sm,
  PIECE: STRING.piece,
  PIECE_BG: STRING.piece_bg,
  SERVING_SM: STRING.serving_sm,
  SERVING: STRING.serving,
  SERVING_BG: STRING.serving_bg,
  GLASS: STRING.glass,
  PACKET: STRING.packet,
  PLATE: STRING.plate,
  PINCH: STRING.pinch,
  CUP: STRING.cup,
  BOTTLE: STRING.bottle,
  FLASK: STRING.flask,
  SLICE: STRING.slice,
  CANNED: STRING.canned,
  CAN_BG: STRING.can_bg,
  CAN: STRING.can,
  CAN_SM: STRING.can_sm,
  BAR: STRING.bar,
  SQUARE: STRING.square,
  DUMPLING: STRING.dumpling,
  BOX: STRING.box,
  // OZ: STRING.oz,
  // QUART: STRING.quart,
  // FL_OZ: STRING.fl_oz,
  LB: STRING.lb,
  LEAF: STRING.leaf,
  TSP: STRING.tsp,
  TBSP: STRING.tbsp,
  UNIT: STRING.unit,
  HANDFUL: STRING.handful,
  CLOVE: STRING.clove,
  STALK: STRING.stalk,
};
