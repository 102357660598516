import React from 'react';
// import PropTypes from 'prop-types'
import {Provider} from 'react-redux';
import {reduxStore} from './redux/reduxStore';
import App from './components/App';

Object.from = arr => ({...arr.map(([k, v]) => ({[k]: v}))});
Object.filter = (obj, predicate) =>
  Object.from(Object.entries(obj).filter(predicate));

// Object.filter = (obj, predicate) =>
//     ({...Object.keys(obj)
//         .filter( key => predicate(obj[key]) )
//         .map( key => ({ [key]: obj[key] }) ) });

const Root = () => (
  <Provider store={reduxStore}>
    <App />
  </Provider>
);

export default Root;
