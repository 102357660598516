export const LECTORED = 'LECTORED';
export const MODIFIED = 'MODIFIED';
export const USDA_LECTORED = 'USDA_LECTORED';
export const HALFLECTORED = 'HALFLECTORED';
export const UNLECTORED = 'UNLECTORED';
export const USER_LOCAL = 'USER_LOCAL';
export const USER_LOCAL_CHECKED = 'USER_LOCAL_CHECKED';
export const DELETED = 'DELETED';

export const lectorOrder = {
  [LECTORED]: 0,
  [MODIFIED]: 1,
  [HALFLECTORED]: 1.2,
  [USDA_LECTORED]: 1.5,
  [UNLECTORED]: 2,
  [DELETED]: 3,
  [USER_LOCAL_CHECKED]: 4,
  [USER_LOCAL]: 5,
};
export const lectorsNext = {
  [HALFLECTORED]: MODIFIED,
  [MODIFIED]: LECTORED,
  [LECTORED]: HALFLECTORED,
  [USER_LOCAL_CHECKED]: USER_LOCAL,
  [USER_LOCAL]: USER_LOCAL_CHECKED,
}