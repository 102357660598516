import React from 'react';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';

import AuthForm from './AuthForm';
import { connect } from 'react-redux';
import {
  changeScreenMode,
  searchWord,
  setModalIsOpen,
} from '../../redux/actions';
import { getStats, newFoodKalori } from '../../redux/foodtrend_actions';
import { requestFoodSearch } from '../../redux/food_actions';
import Leaves from '../../assets/leaves.png';
import { green } from '../../utils/colors_helper';
import { LanguageMultiSelect } from '../comparation/MultiSelect';

// import { Button, Nav, Navbar, NavItem, NavDropdown } from 'react-bootstrap';

class Navigation extends React.PureComponent {
  state = {
    value: '',
    isOpen: true,
    filterOpen: false
  };
  componentDidMount = () => {
    // window.addEventListener('resize', e => {
    //   let width = e.target.outerWidth;
    //   console.log('width', width);
    //   this.setState({ isOpen: width > 1300 });
    // });
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  setFilterOpen = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    });
  };
  render = () => {
    const { dispatch, searchText } = this.props;
    const { isOpen } = this.state;
    console.log('isOpen', isOpen);

    return (
      <header>
        <Navbar color={green} light expand="xs">
          <NavbarBrand href="/">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  height: 44,
                  width: 44,
                  borderRadius: 24,
                  backgroundColor: 'white',
                  marginRight: 16,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <img
                  style={{ height: 24, width: 24 }}
                  src={Leaves}
                  alt="icon"
                />
              </div>
              <div style={{ display: 'flex', color: 'white' }}>
                FoodTrend
                <div
                  style={{
                    display: 'inline-block',
                    color: '#ddd',
                    textTransform: 'uppercase',
                    fontSize: '0.7em',
                  }}
                />
              </div>
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} className="mr-2" />
          {/*<Collapse isOpen={isOpen} navbar>*/}
          <Nav className="ml-auto d-flex align-items-center" navbar>
            <NavItem className="d-flex align-items-center">
              <AuthForm />
            </NavItem>
            {this.props.food_user && (
              <React.Fragment>
                <NavItem>
                  <input
                    style={{ marginRight: 8 }}
                    onChange={e =>
                      dispatch(requestFoodSearch(e.target.value, 1000))
                    }
                    value={searchText}
                    placeholder={'Search...'}
                  />
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret style={{ color: 'white' }}>
                    Options
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => dispatch(newFoodKalori())}>
                      Add Food
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => dispatch(setModalIsOpen(true))}
                    >
                      Columns
                    </DropdownItem>
                    <DropdownItem onClick={this.setFilterOpen}>
                      Search languages
                    </DropdownItem>
                    <DropdownItem onClick={() => dispatch(getStats())}>
                      Stats
                    </DropdownItem>
                    <DropdownItem onClick={() => dispatch(changeScreenMode())}>
                      Recogtrend
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </React.Fragment>
            )}
          </Nav>
          {/*</Collapse>*/}
        </Navbar>
        <LanguageMultiSelect isOpen={this.state.filterOpen} onClose={this.setFilterOpen} />
      </header>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const { searchText } = state.controllers;
  return { searchText, food_user: state.user.food };
};

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
