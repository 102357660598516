import _ from 'lodash';
import {proParseFloat} from './parsers';

export const getRecipeWeight = ({recipe, metrics, others}) => {
  if (others?.weight) {
    return others.weight;
  }
  const serving = metrics?.find(o => o.value === 'SERVING');
  return serving ? serving.weights * (others?.serves ?? 1) : getRecipeFullWeight(recipe);
};
export const getRecipeFullWeight = (recipe) => {
  return recipe ? _.isArray(recipe) ? recipe.reduce((acc, {id, ...item}) =>
      acc + proParseFloat(item?.weights) * proParseFloat(item?.amount), 0) :
    Object.entries(recipe).reduce((acc, [id, item]) =>
      acc + proParseFloat(item?.weights) * proParseFloat(item?.amount), 0) : null;
};

export const getRecipeContainWithDb = (recipe, key, foodDb) => {
  return recipe ? _.isArray(recipe) ? recipe.reduce((acc, {id, ...item}) =>
      acc + (foodDb?.[id]?.details?.[key] ? foodDb?.[id].details?.[key] * item?.weights * item?.amount / 100 : 0), 0) :
    Object.entries(recipe).reduce((acc, [id, item]) =>
      acc + (foodDb?.[id]?.details?.[key] ? foodDb?.[id].details?.[key] * item?.weights * item?.amount / 100 : 0), 0) : null;
};
