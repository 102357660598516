/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import { setModalIsOpen, toggleColumn } from '../../redux/actions';
import { all_columns } from '../../utils/food_helper';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { useColumnStore } from '../foods-list/ColumnOrder';

const SettingsModal = props => {
  const { isOpen } = props;

  const handleChange = name => event => {
    useColumnStore.getState().toggleColumn(name);
  };
  const { selectedColumns, ...s } = useColumnStore();
  useEffect(() => {}, []);
  return (
    <Dialog
      open={isOpen}
      onClose={props.closeModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Which columns do you need?</DialogTitle>
      <DialogContent>
        <FormGroup>
          {all_columns.map(v => {
            return (
              <FormControlLabel
                key={v}
                control={
                  <Checkbox
                    checked={_.includes(selectedColumns, v)}
                    onChange={handleChange(v)}
                  />
                }
                label={v}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.closeModal}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.controllers.modalIsOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
  closeModal: () => {
    dispatch(setModalIsOpen(false));
  },
});

const SettingsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsModal);

export default SettingsModalContainer;
