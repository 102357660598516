/*
 * action types
 */

import { requestFoodSearch } from './usda_actions';
import { replaceAccentedChars } from '../utils/utils';
import { getFoodDb, requestAllFood, useFoodDbStore } from './food_actions';

export const SET_FOOD_USER = 'SET_FOOD_USER';
export const SET_FOODS = 'SET_FOODS';
export const CHANGE_FOOD = 'CHANGE_FOOD';
export const SET_GRAPH_USER = 'SET_GRAPH_USER';
export const SELECT_ORDER_COLUMN = 'SELECT_ORDER_COLUMN';
export const TOGGLE_COLUMN = 'TOGGLE_COLUMN';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SELECT_FOOD_ID = 'SELECT_RUN_ID';
export const TOGGLE_FOOD_ID = 'TOGGLE_FOOD_ID';
export const TOGGLE_MODAL_IS_OPEN = 'TOGGLE_MODAL_IS_OPEN';
export const SCROLL_TO_INDEX = 'SCROLL_TO_INDEX';
export const SEARCH_CHANGE = 'SEARCH_CHANGE';

export const SWITCH_SCREEN = 'SWITCH_SCREEN';

export const SET_ENG_NAME = 'SET_ENG_NAME';
export const SET_HUN_NAME = 'SET_HUN_NAME';

/*
 * other constants
 */

/*
 * action creators
 */

export const initFirebaseLogin = () => (dispatch, getState, firebase) => {
  const onAuthStateChange = user => {
    if (user) {
      dispatch(requestAllFood());
    }
    dispatch(setFoodUser(user));
  };
  if (firebase.auth().currentUser)
    onAuthStateChange(firebase.auth().currentUser);
  firebase.auth().onAuthStateChanged(onAuthStateChange);
};

export function setGraphUser(user) {
  const { email, uid, displayName, emailVerified, refreshToken } = user
    ? user
    : {};
  user = user ? { email, uid, displayName, emailVerified, refreshToken } : user;
  return { type: SET_GRAPH_USER, user: user };
}

export function setFoodUser(user) {
  const { email, uid, displayName, emailVerified, refreshToken } = user
    ? user
    : {};
  user = user ? { email, uid, displayName, emailVerified, refreshToken } : user;
  return { type: SET_FOOD_USER, user: user };
}

export const setFoods = foods => (dispatch, getState, firebase) => {
  dispatch({ type: SET_FOODS, foods });
};

export const setFoodValidation = (id, isGood) => (
  dispatch,
  getState,
  firebase,
) => {
  const state = getState();
  const food = state.foods[id];
  const { uid } = state.user.food;
  if (!food.selected) {
    food['selected'] = {};
  }
  food.selected.good = isGood;
  dispatch({ type: CHANGE_FOOD, food: { [id]: food } });
  firebase
    .database()
    .ref('/foods/' + id + '/selected/')
    .update({ good: isGood, goodUID: uid });
};

export const changeFoodHunName = (id, name) => (
  dispatch,
  getState,
  firebase,
) => {
  const state = getState();
  const food = state.foods[id];
  const { uid } = state.user.food;
  food.localisations[0].names[0].value = name;
  dispatch({ type: CHANGE_FOOD, food: { [id]: food } });
  firebase
    .database()
    .ref('/foods/' + id + '/localisations/0/names/0/value')
    .set(name);
  firebase
    .database()
    .ref('/foods/' + id + '/selected/')
    .update({ hunUID: uid });
};

export const changeFoodEngName = (id, name) => (
  dispatch,
  getState,
  firebase,
) => {
  const state = getState();
  const food = state.foods[id];
  const { uid } = state.user.food;
  food.localisations[1].names[0].value = name;
  dispatch({ type: CHANGE_FOOD, food: { [id]: food } });
  firebase
    .database()
    .ref('/foods/' + id + '/localisations/1/names/0/value')
    .set(name);
  firebase
    .database()
    .ref('/foods/' + id + '/selected/')
    .update({ engUID: uid });
};

export function setColumns(columns) {
  return { type: SET_COLUMNS, columns };
}
export function toggleColumn(column) {
  return { type: TOGGLE_COLUMN, column };
}

export function setModalIsOpen(isOpen) {
  return { type: TOGGLE_MODAL_IS_OPEN, isOpen };
}

export const selectFoodID = food_id => (dispatch, getState) => {
  dispatch({ type: SELECT_FOOD_ID, food_id });
};

export const selectOrderColumn = column => (dispatch, getState) => {
  dispatch({ type: SELECT_ORDER_COLUMN, orderColumn: column });
};

export function toggleFoodID(food_id) {
  return { type: TOGGLE_FOOD_ID, food_id };
}

export function scrollToIndexRequest(scrollToIndex) {
  return { type: SCROLL_TO_INDEX, scrollToIndex };
}

export function changeScreenMode() {
  return { type: SWITCH_SCREEN };
}

export const searchWord = text => (dispatch, getState) => {
  dispatch({
    type: SEARCH_CHANGE,
    text: text,
  });
};
