import _ from 'lodash';

const colorScale = [
  '#4dea31',
  '#83ee4e',
  '#9fd032',
  '#bdcb22',
  '#dabd41',
  '#f3b66e',
  '#ed9559',
  '#e67b4a',
  '#de5436',
  '#da382a',
];

export const getIntelligentColor = score => {
  let v = score >= 1 ? 1 : score;
  v = _.floor(v * (_.size(colorScale) - 1));
  return colorScale[v];
};

export const green = '#2ECC71';
export const secondaryGreen = '#27AE60';
export const darkerBackground = '#f0f0f0';
export const darkBackground = '#f5f5f5';
export const background = '#fff';
