import firebase from 'firebase';
import moment from 'moment';

const config = {
  apiKey: 'AIzaSyCniMsIuP1F6ZrXAPOm6Yr04mpEPd0vlRw',
  authDomain: 'foodtrend-db.firebaseapp.com',
  databaseURL: 'https://foodtrend-db.firebaseio.com',
  projectId: 'foodtrend-db',
  storageBucket: 'foodtrend-db.appspot.com',
  messagingSenderId: '827322308133',
};

let firebaseFoodtrendDb = firebase.initializeApp(config, 'foodtrend-db');

firebaseFoodtrendDb.auth().onAuthStateChanged(user => {
  if (user) {
    console.info('[foodtrend] Uid:', user.uid, 'User:', user);
    firebaseFoodtrendDb
      .database()
      .ref()
      .child('users')
      .child(user.uid)
      .child('last_login')
      .set(new Date().getTime());
  } else {
    console.info('[foodtrend] User: null');
  }
});

export default firebaseFoodtrendDb;

// gsutil cors set cors.json gs://foodtrend-db.appspot.com

