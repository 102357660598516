import React, {useState} from 'react';
import {interpolator} from '../../utils/interpolator';
import {darkBackground, getIntelligentColor} from '../../utils/colors_helper';
import Input from '@material-ui/core/Input';
import {changeFoodContains, changeFoodFluidity, updateFoodContains} from '../../redux/foodtrend_actions';
import {isNan} from '../../utils/utils';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import {Column} from '../buildingblocks/OneLiners';
import {Button, Col, Dropdown, Form} from 'react-bootstrap';
import ControlledInput from '../buildingblocks/ControlledInput';
import {getRecipeContain100g} from '../../utils/dtjs/recipes';
import {proParseFloat} from '../../utils/dtjs/parsers';

export const relativeColoring = relVal => {
  const fromArray = [1, 1 / 1.15, 1 / 1.5, 1 / 2, 1 / 4];
  const toArray = [0, 0.2, 0.4, 0.6, 0.8, 1];
  const gtVal = relVal > 1 ? 1 / relVal : relVal;
  const interpolatedVal = interpolator(gtVal, fromArray, toArray);
  return getIntelligentColor(interpolatedVal);
};
const RecipeContain = ({field, refField, item}) => {
  const value = getRecipeContain100g(item, field);
  return value ?
    <div style={{color: value && refField ? value > refField * 1.2 || value < refField / 1.2 ? 'orange' : 'green' : 'black'}}>/{value.toFixed(0)}</div>
    : null;
};
const GetNiceContain = (props) => {
  const {name, title, id, dispatch, ...item} = props;
  const {recipe, metrics, details} = item;
  const [state, setstate] = useState(details?.[name]);
  const unit = foodContainsTypes().find(o => o.key === name);
  return (
    <div style={{flexDirection: 'column'}}>
      <div>{title ?? name}:</div>
      <div style={{
        display: 'flex',
        marginRight: 16,
        marginBottom: 8,
        alignItems: 'center',
      }}>
        <input
          style={styles.inputStyle}
          onChange={e => {
            setstate(e.target.value);
            if (e.target.value !== '' && isNan(e.target.value)) {
              alert('Nan');
              return;
            }
            dispatch(
              changeFoodContains(
                id,
                name,
                e.target.value === '' ? null :
                  proParseFloat(e.target.value.replace(',', '.')),
              ),
            );
          }}
          value={state ?? ''}
          placeholder={'...'}
          disableUnderline={true}
        />
        <RecipeContain field={name} refField={state} item={item}/>
        {unit?.unit ? <div style={{marginLeft: 4}}>{unit?.unit}</div> : null}
      </div>
    </div>
  );
};
const importantDetails = [
  'calorie', 'fat', 'satfa', 'monofa', 'polyfa', 'chol',
  'carbohydrate', 'totsug', 'sugr_alc',
  'fibt', // 'fiber',

  'starch',
  'protein',
  'salt',
  'water', 'alcohol',
  'fe', 'ca', 'na', 'k', 'p', 'zn',
  'mg', 'cu', 'se', 'cl', 'i', 'mn',
  'vita', 'vitb1', 'vitb2', 'vitc', 'vitb3', 'vitb5', 'vitb6', 'vitb7', 'vitb9', 'vitb12', 'vite', 'vitd', 'vitk1',
  'retequ', 'carequ', 'tryp60',
  'caffeine',
  'gi',
  // "gluc", "fruct",  "galact", "sucr", "malt", "lact",
];
const isGiAvailableDetails = null;
const calorieEstimator = null;

export const foodContainsTypes = () => [
  {key: 'carbohydrate', unit: ' g'},
  {key: 'totsug', unit: ' g', inner: true}, //
  {key: 'fat', unit: ' g'},
  {key: 'protein', unit: ' g'},
  {key: 'water', unit: ' g'},
  {key: 'calorie', unit: ' kcal', estimator: calorieEstimator},
  {key: 'fiber', unit: ' g'},
  {key: 'fibt', unit: ' g'}, // TODO ? fibt
  {key: 'sugr_alc', unit: ' g', inner: true}, // TODO Later on remove this use polyol
  {key: 'polyol', unit: ' g'},
  {key: 'sugr', unit: ' g', inner: true}, // TODO
  {key: 'starch', unit: ' g', inner: true}, // TODO later on remove this use star
  {key: 'star', unit: ' g', inner: true},
  {key: 'satFat', unit: ' g'}, // TODO later on remove this use satfa
  {key: 'satfa', unit: ' g', inner: true},
  {key: 'monofa', unit: ' g', inner: true}, // TODO
  {key: 'transfa', unit: ' g', inner: true}, // TODO
  {key: 'polyfa', unit: ' g', inner: true}, // TODO
  {key: 'caffeine', unit: ' g'},
  {key: 'salt', unit: ' g'}, // TODO later on remove this use star
  {key: 'sodium', unit: ' mg'}, // TODO later on remove this, but this is really late.
  {key: 'na', unit: ' mg'},
  {key: 'alcohol', unit: '%'},
  {key: 'alco', unit: '%'},
  {key: 'vita-c', unit: ' g'}, // TODO later on remove this use vitc
  {key: 'gi', unit: '', isAvailable: isGiAvailableDetails},
  {key: 'gistd', unit: '', isAvailable: isGiAvailableDetails},
  {key: 'chol', unit: ' mg'}, // TODO
  {key: 'ca', unit: ' mg'}, // TODO
  {key: 'cl', unit: ' mg'}, // TODO
  {key: 'cu', unit: ' mg'}, // TODO
  {key: 'fe', unit: ' mg'}, // TODO
  {key: 'mg', unit: ' mg'}, // TODO
  {key: 'mn', unit: ' mg'}, // TODO
  {key: 'zn', unit: ' mg'}, // TODO
  {key: 'se', unit: ' µg'}, // TODO
  {key: 'p', unit: ' µg'}, // TODO
  {key: 'i', unit: ' µg'}, // TODO
  {key: 'k', unit: ' mg'}, // TODO
  {key: 'tryp60', unit: ' mg'}, // TODO
  {key: 'niacequ', unit: ' mg'}, // TODO
  {key: 'vita', unit: ' µg'},
  {key: 'vitb1', unit: ' mg'},
  {key: 'vitb2', unit: ' mg'},
  {key: 'vitb3', unit: ' mg'},
  {key: 'vitb5', unit: ' mg'},
  {key: 'vitb6', unit: ' mg'},
  {key: 'vitb7', unit: ' µg'},
  {key: 'vitb9', unit: ' µg'},
  {key: 'vitb12', unit: ' µg'},
  {key: 'vita_c', unit: ' mg'},
  {key: 'vitc', unit: ' mg'},
  {key: 'vitd', unit: ' µg'},
  {key: 'vite', unit: ' mg'},
  {key: 'vitk1', unit: ' µg'},
];
const ContainsLine = props => {
  const {name, title, id, dispatch} = props;
  const notShowDetails = props.details ? Object.keys(props.details).filter(k => !importantDetails.includes(k)) : [];
  return (
    <div
      style={{
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: 4,
      }}
    >
      {importantDetails.map(o => <GetNiceContain key={o} {...props} name={o}/>)}
      <GetNiceContain {...props} name={'gistd'} title={'±gi(std)'}/>
      <Column>
        <div style={styles.label}>Fluid:</div>
        <Checkbox
          checked={props.fluid}
          color="primary"
          onChange={e =>
            props.dispatch(changeFoodFluidity(props.id, e.target.checked))
          }
        />
      </Column>
      {notShowDetails.map(o => <GetNiceContain {...props} name={o}/>)}
      <Button color="primary" onClick={() => {
        const update = importantDetails.reduce((acc, o) => {
          if (o === 'gi') return acc;
          const value = getRecipeContain100g(props, o);
          acc[o] = value;
          return acc;
        }, {});
        console.log('update', update);
        dispatch(updateFoodContains(id, update));
      }}>
        Copy all nutrition estimations
      </Button>
    </div>
  )
    ;
};

const styles = {
  label: {},
  inputStyle: {
    backgroundColor: darkBackground,
    borderWidth: 0,
    paddingLeft: 8,
    width: 50,
  },
};

export default ContainsLine;
