import create from 'zustand';
import {persist} from 'zustand/middleware';
import _ from 'lodash';
import {produce} from 'immer';
import {proParseFloat} from '../../utils/dtjs/parsers';

export const init_columns = [
  'ID',
  'EN',
  'HU',
  'Carb',
  'Fiber',
  'Frequency',
  'Lector',
  'Extra',
];
export const conditionTypes = {
  NOT_SELECTED: (a, b) => true,
  EQUAL: (a, b) => typeof a == 'number' ? a === proParseFloat(b) : a === b,
  LESS: (a, b) => typeof a == 'number' ? a < proParseFloat(b) : a < b,
  MORE: (a, b) => typeof a == 'number' ? a > proParseFloat(b) : a > b,
  ISEMPTY: (a, b) => !a || a === '',
  NONEMPTY: (a, b) => a && a !== '',
  NOTEQUAL: (a, b) => a != b,
};
export const conditionFilterDefault = () => ({
  enable: false,
});
const filtersExample = {
  Fiber: {
    enabled: false,
    conditionType: 'EQUAL',
    condítionValue: '12',
    valuesShown: ['LECTORED'],
  },
};

export const useColumnStore = create(
  persist(
    (set, get) => {
      return {
        selectedColumns: init_columns,
        toggleColumn: column =>
          set({
            selectedColumns: _.xor(get().selectedColumns, [column]),
          }), // It is completly separate from the below rows.
        sortedColumns: [],
        reverses: [],
        filters: {},
        setFilters: (columnName, patch) => {
          set(produce(s => {
            if (!s.filters.hasOwnProperty(columnName)) {
              s.filters[columnName] = {enabled: false};
            }
            Object.entries(patch).map(([k, v]) => s.filters[columnName][k] = v);
          }));
        },
        setFiltersToggleShown: (columnName, option) => {
          set(produce(s => {
            if (!s.filters.hasOwnProperty(columnName)) {
              s.filters[columnName] = {enabled: false};
            }
            const shown = s.filters[columnName].valuesShown ?? {};
            if (shown.hasOwnProperty(option)) {
              delete s.filters[columnName].valuesShown[option];
            } else {
              shown[option] = true;
              s.filters[columnName].valuesShown = shown;
            }
            s.filters[columnName].enabled = true;
          }));
        },
        setSortFn: columnName => {
          const {reverses, sortedColumns} = get();
          // if (!columnName) {
          //   set(s => ({
          //     ...s,
          //     sortFn: null,
          //     reverse: false,
          //     sortedColumn: null,
          //   }));
          //   return;
          // }
          const index = sortedColumns.findIndex(c => c === columnName);
          const shouldReverse = index !== -1 && reverses[index] === false;
          const shouldRemove = index !== -1 && reverses[index] === true;
          if (shouldReverse) {
            set(s => ({
              ...s,
              reverses: reverses.map((r, i) => (i === index ? !r : r)),
            }));
          } else if (shouldRemove) {
            set(s => ({
              ...s,
              reverses: reverses.filter((r, i) => i !== index),
              sortedColumns: sortedColumns.filter((r, i) => i !== index),
            }));
          } else {
            set(s => ({
              ...s,
              sortedColumns: [...sortedColumns, columnName],
              reverses: [...reverses, false],
            }));
          }
        },
      };
    },
    {
      name: 'col-storage-6', // unique name
      getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    },
  ),
);
