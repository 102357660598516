import React from 'react';

export const PopupContainer = ({children, onClose, style, ...props}) => <div
  style={{
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
  <div style={{
    backgroundColor: 'black', width: '100%', height: '100%', opacity: 0.5, left: 0, top: 0,
  }} onClick={e => {
    onClose(e);
    e.stopPropagation();
  }}/>
  <div style={{
    position: 'absolute', backgroundColor: 'white', opacity: 1, padding: '20px', ...style,
  }} onClick={e => {
    e.stopPropagation();
  }}>{children}</div>
</div>;
