import React from 'react';
import {VictoryPie} from 'victory';
import {getRecipeContain} from '../../utils/dtjs/recipes';


const MealSummary = React.memo(({refFood}) => {
  const ch = getRecipeContain(refFood?.recipe, 'carbohydrate');
  const prot = getRecipeContain(refFood?.recipe, 'protein');
  const fat = getRecipeContain(refFood?.recipe, 'fat');
  const fatCorrected = fat * 9 / 4; // Usually the kcal values are in 4/4/9 ratio.
  const sumCorrected = ch + prot + fatCorrected;

  return (
    <div style={{height: 240}}>
      <VictoryPie
        padding={{top: 0, bottom: 0, left: 0, right: 0}}
        standalone={true}
        width={180}
        height={180}
        labelRadius={({innerRadius}) => innerRadius + 3}
        style={{labels: {fill: 'white', fontSize: 12, fontWeight: 'bold', fontAlign: 'center'}}}
        colorScale={['#2980B9', '#51acec', '#27AE60']}
        data={[
          {x: 'Ch', y: ch, flat: ch},
          {x: 'Prot', y: prot, flat: prot},
          {x: 'Fat', y: fatCorrected, flat: fat},
        ].map(o => ({...o, x: o.x + `\n${(o.flat / refFood.others?.serves).toFixed(0)}g\n${(o.y * 100 / sumCorrected).toFixed(0)}%`}))}
        innerRadius={50}
        responsive={true}
      />
    </div>
  );
});

export default MealSummary;
