import moment from 'moment';
import _ from 'lodash';
import 'moment/locale/hu';
import 'moment/locale/en-gb';

const existingLocale = systemLanguage => {
  const existingLanguageCodes = ['en', 'hu'];
  const firstTwoLetters = systemLanguage.substring(0, 2);
  return existingLanguageCodes.includes(firstTwoLetters)
    ? firstTwoLetters
    : 'en';
};

export const locale = existingLocale('hu_HU');
export const initLanguage = () => {
  console.log('locale', locale);
  moment.locale(locale);
};

const STRING = {
  //Account
  personal: {
    en: 'Personal',
    hu: 'Személyes',
  },
  givenName: {
    en: 'Given name',
    hu: 'Keresztnév',
  },
  familyName: {
    en: 'Family name',
    hu: 'Családnév',
  },
  email: {
    en: 'Email',
    hu: 'Email',
  },
  gender: {
    en: 'Gender',
    hu: 'Nem',
  },
  weight: {
    en: 'Weight',
    hu: 'Testsúly',
  },
  height: {
    en: 'Height',
    hu: 'Magasság',
  },
  birthDate: {
    en: 'Birthdate',
    hu: 'Születésnap',
  },
  health: {
    en: 'Health',
    hu: 'Egészség',
  },
  usedInsulintypes: {
    en: 'Used insulins',
    hu: 'Használt inzulinok',
  },
  diabetesDiagnosed: {
    en: 'Diabetes diagnosed',
    hu: 'Diabétesz diagnosztizálva',
  },
  isInsulinpump: {
    en: 'Insulinpump user',
    hu: 'Inzulinpumpás',
  },
  settings: {
    en: 'Settings',
    hu: 'Beállítások',
  },
  export: {
    en: 'Export data',
    hu: 'Adatok exportálása',
  },
  connect: {
    en: 'Connect',
    hu: 'Csatlakozás',
  },
  connections: {
    en: 'Connections',
    hu: 'Kapcsolatok',
  },
  preferredUnit: {
    en: 'Preferred units',
    hu: 'Preferált mértékegység',
  },
  logout: {
    en: 'Logout',
    hu: 'Kijelentkezés',
  },
  // Event ADD/DETAILS
  areYouSure: {
    en: 'Are you sure you want to delete this event?',
    hu: 'Biztos vagy benne, hogy törölni akarod az eseményt?',
  },
  menstruation: {
    en: 'Menstruation',
    hu: 'Period',
  },
  hba1c: {
    en: 'HbA1c',
    hu: 'HbA1c',
  },
  illness: {
    en: 'Illness',
    hu: 'Betegség',
  },
  hypo: {
    en: 'Hypo',
    hu: 'Hypo',
  },
  add: {
    en: 'ADD',
    hu: 'FELVÉTEL',
  },
  done: {
    en: 'Done',
    hu: 'Kész',
  },
  coolStats: {
    en: 'Cool stats, keep up the good work!',
    hu: 'Szép értékek, csak így tovább!',
  },
  prediction: {
    en: 'Prediction',
    hu: 'Előrejelzés',
  },
  accuracy: {
    en: 'Accuracy',
    hu: 'Pontosság',
  },
  refreshed: {
    en: 'Refreshed',
    hu: 'Frissítve',
  },
  glucose: {
    en: 'Glucose',
    hu: 'Vércukor',
  },
  insulin: {
    en: 'Insulin',
    hu: 'Inzulin',
  },
  meal: {
    en: 'Meal',
    hu: 'Étkezés',
  },
  exercise: {
    en: 'Exercise',
    hu: 'Mozgás',
  },
  sleep: {
    en: 'Sleep',
    hu: 'Alvás',
  },
  // Event ADD
  time: {
    en: 'Time',
    hu: 'Idő',
  },
  intoStomach: {
    en: 'Into stomach',
    hu: 'Hasba',
  },
  InzulinName: {
    en: 'Insulin',
    hu: 'Inzulin',
  },
  dose: {
    en: 'Dose',
    hu: 'Dózis',
  },
  foods: {
    en: 'Foods',
    hu: 'Ételek',
  },
  photo: {
    en: 'Photo',
    hu: 'Fotó',
  },
  start: {
    en: 'Start',
    hu: 'Kezdete',
  },
  end: {
    en: 'End',
    hu: 'Vége',
  },
  value: {
    en: 'Value',
    hu: 'Érték',
  },
  source: {
    en: 'Source',
    hu: 'Forrás',
  },
  comment: {
    en: 'Comment',
    hu: 'Komment',
  },
  fever: {
    en: 'Fever',
    hu: 'Láz',
  },
  none: {
    en: 'None',
    hu: 'Nincs',
  },
  slight: {
    en: 'Slight',
    hu: 'Enyhe',
  },
  high: {
    en: 'High',
    hu: 'Magas',
  },
  easy: {
    en: 'Easy',
    hu: 'Könnyű',
  },
  medium: {
    en: 'Medium',
    hu: 'Közepes',
  },
  intense: {
    en: 'Intense',
    hu: 'Intenzív',
  },
  intensity: {
    en: 'Intensity',
    hu: 'Intenzítás',
  },
  steps: {
    en: 'Steps',
    hu: 'Lépések',
  },
  type: {
    en: 'type',
    hu: 'típus',
  },
  cup: {
    en: 'cup',
    hu: 'csésze',
  },
  plate: {
    en: 'plate',
    hu: 'tál',
  },
  pinch: {
    en: 'pinch',
    hu: 'csipet',
  },
  can_sm: {
    en: 'small can',
    hu: 'kis konzerv',
  },
  can_bg: {
    en: 'big can',
    hu: 'nagy konzerv',
  },
  mililiter: {
    en: 'ml',
    hu: 'ml',
  },
  gram: {
    en: 'gram',
    hu: 'gramm',
  },
  packet: {
    en: 'packet',
    hu: 'csomag',
  },
  oz: {
    en: 'oz',
    hu: 'uncia',
  },
  slice: {
    en: 'slice',
    hu: 'szelet',
  },
  piece: {
    en: 'piece',
    hu: 'darab',
  },
  bottle: {
    en: 'bottle',
    hu: 'üveg',
  },
  flask: {
    en: 'flask',
    hu: 'palack',
  },
  glass: {
    en: 'glass',
    hu: 'pohár',
  },
  can: {
    en: 'can',
    hu: 'konzerv',
  },
  canned: {
    en: 'canned',
    hu: 'doboz(sör)',
  },
  bar: {
    en: 'bar',
    hu: 'tábla',
  },
  square: {
    en: 'square',
    hu: 'kocka',
  },
  dumpling: {
    en: 'dumpling',
    hu: 'gombóc',
  },
  box: {
    en: 'box',
    hu: 'doboz',
  },
  quart: {
    en: 'quart',
    hu: '1/4 gallon',
  },
  fl_oz: {
    en: 'fl oz',
    hu: 'foly. uncia',
  },
  serving: {
    en: 'serving',
    hu: 'adag',
  },
  piece_sm: {en: 'small piece', hu: 'kis darab'},
  piece_bg: {en: 'big piece', hu: 'nagy darab'},
  serving_sm: {en: 'small serving', hu: 'kis adag'},
  serving_bg: {en: 'big serving', hu: 'nagy adag'},
  serving_bg_abrv: {en: 'big srv.', hu: 'nagy adag'},
  serving_sm_abrv: {en: 'small srv.', hu: 'kis adag'},
  serving_abrv: {en: 'srv', hu: 'adag'},
  piece_abrv: {en: 'pc.', hu: 'db'},
  piece_sm_abrv: {en: 'small pc.', hu: 'kis db'},
  piece_bg_abrv: {en: 'big pc.', hu: 'nagy db'},
  patty: {
    en: 'patty',
    hu: 'pogácsa',
  },
  pie: {
    en: 'pie',
    hu: 'pite',
  },
  head: {
    en: 'head',
    hu: 'fej',
  },
  leaf: {
    en: 'leaf',
    hu: 'levél',
  },
  tsp: {
    en: 'tsp',
    hu: 'teáskanál',
  },
  tbsp: {
    en: 'tbsp',
    hu: 'evőkanál',
  },
  bag: {
    en: 'bag',
    hu: 'táska',
  },
  order: {
    en: 'order',
    hu: 'rendelés',
  },
  link: {
    en: 'link',
    hu: 'ízület',
  },
  unit: {
    en: 'unit',
    hu: 'egység',
  },
  lb: {
    en: 'lb',
    hu: 'font',
  },
  pod: {
    en: 'pod',
    hu: 'hüvely',
  },
  otherEvents: {
    en: 'Other events',
    hu: 'Egyéb eventek',
  },
  // Statistics
  noData: {
    en: '- - -',
    hu: '- - -',
  },
  // Auth
  authLoading: {
    en: 'AuthLoading',
    hu: 'Belépés',
  },
  password: {
    en: 'Password',
    hu: 'Jelszó',
  },
  gotAnAccount: {
    en: 'Got an account?',
    hu: 'Van felhasználód?',
  },
  signInSwitcher: {
    en: 'Sign in',
    hu: 'Jelentkezz be',
  },
  signIn: {
    en: 'Log in with Email',
    hu: 'Bejelentkezés E-maillel',
  },
  facebookSignIn: {
    en: 'Log in with Facebook',
    hu: 'Bejelentkezés Facebookkal',
  },
  register: {
    en: 'Register',
    hu: 'Regisztráció',
  },
  newPassword: {
    en: 'Reset my password',
    hu: 'Új jelszó igénylése',
  },
  fbRegisterCancelled: {
    en: 'Facebook register cancelled by You',
    hu: 'Facebook regisztráció általad megszakítva',
  },
  somethingWentWrongFacebook: {
    en: 'Something went wrong obtaining the users access token',
    hu: 'Nem sikerült megkapni a user hozzáférési tokent',
  },
  noAccountQuestion: {
    en: 'No account yet?',
    hu: 'Nincs felhasználód?',
  },
  forgotPassword: {
    en: 'Forgot password?',
    hu: 'Elfelejtett jelszó?',
  },
  resetPassword: {
    en: 'Reset!',
    hu: 'Kérj újat!',
  },
  registrationSwitcher: {
    en: 'Sign up',
    hu: 'Regisztráció',
  },
  loading: {
    en: 'Loading...',
    hu: 'Betöltés...',
  },
  invalidEmail: {
    en: 'Use a valid email address',
    hu: 'Nem érvényes email cím',
  },
  tooShortPassword: {
    en: 'Too short password',
    hu: 'Túl rövid jelszó',
  },
  emptyEmail: {
    en: 'No email address given!',
    hu: 'Adj meg emailt!',
  },
  givenNameRequired: {
    en: 'Given name required',
    hu: 'Keresztnév szükséges',
  },
  familyNameRequired: {
    en: 'Family name required',
    hu: 'Család név szükséges',
  },
  passwordsDontMatch: {
    en: 'Passwords don\'t match',
    hu: 'A jelszó nem egyezik',
  },
  acceptingTermsRequired: {
    en: 'To register you need to accept the Terms of use',
    hu: 'A regisztráláshoz el kell fogadnod a Felhasználási feltétlekete',
  },
  // Food search
  searchFoods: {
    en: 'Search a food',
    hu: 'Étel keresése',
  },
  searchNoResult: {
    en: 'The searched expression (',
    hu: 'A keresett kifejezés (',
  },
  searchNoResult2: {
    en: ') was not found.',
    hu: ') nem található.',
  },
  eventDetails: {
    en: 'Event details',
    hu: 'Esemény részletek',
  },
  newEvent: {
    en: 'New event',
    hu: 'Új esemény',
  },
  editEvent: {
    en: 'Edit event',
    hu: 'Esemény szerkesztés',
  },
  save: {
    en: 'SAVE',
    hu: 'MENTÉS',
  },
  edit: {
    en: 'EDIT',
    hu: 'SZERKESZT',
  },
  yes: {
    en: 'Yes',
    hu: 'Igen',
  },
  no: {
    en: 'No',
    hu: 'Nem',
  },
  diabetesType: {
    en: 'Diabetes type',
    hu: 'Diabétesz típus',
  },
  carbohydrates: {
    en: 'Carbohydrates',
    hu: 'Szénhidrát',
  },
  carbs: {
    en: 'carbs',
    hu: 'szénhidrát',
  },
  proteins: {
    en: 'Proteins',
    hu: 'Fehérje',
  },
  prot: {
    en: 'protein',
    hu: 'fehérje',
  },
  fats: {
    en: 'Fats',
    hu: 'Zsír',
  },
  fat: {
    en: 'fat',
    hu: 'zsír',
  },
  waters: {
    en: 'Waters',
    hu: 'Víz',
  },
  calories: {
    en: 'Calorie',
    hu: 'Kalória',
  },
  caffeine: {
    en: 'Caffeine',
    hu: 'Kaffein',
  },
  sodiums: {
    en: 'Sodium',
    hu: 'Szódium',
  },
  alcohols: {
    en: 'Alcohol',
    hu: 'Alkohol',
  },
  fibers: {
    en: 'Fibers',
    hu: 'Rost',
  },
  'vita-cs': {
    en: 'Vitamin-C',
    hu: 'Vitamin-C',
  },
  addNameFood: {
    en: '+ Add a name for food',
    hu: '+ Ételnév felvétele',
  },
  additionalContains: {
    en: '+ Additional contains',
    hu: '+ További összetevők',
  },
  additionalServings: {
    en: '+ Additional servings',
    hu: '+ További adagok',
  },
  foodDetails: {
    en: 'Food details',
    hu: 'Étel részletek',
  },
  addFood: {
    en: 'Add food',
    hu: 'Étel felvétele',
  },
  components: {
    en: 'Components',
    hu: 'Összetevők',
  },
  otherComponents: {
    en: 'Other components',
    hu: 'Más összetevők',
  },
  servingSizes: {
    en: 'Serving sizes',
    hu: 'Felszolgálási adagok',
  },
  in100g: {
    en: '/100g',
    hu: '/100g',
  },
  //  units
  hour: {
    en: 'h',
    hu: 'óra',
  },
  minute: {
    en: 'min',
    hu: 'perc',
  },
  justNow: {
    en: 'Just now',
    hu: 'Épp most',
  },
  clove: {
    en: 'clove',
    hu: 'gerezd',
  },
  handful: {
    en: 'handful',
    hu: 'marék',
  },
  stalk: { en: 'stalk', hu: 'szár' }
};

const LANG_STRING = _.mapValues(STRING, value => value[locale]);
export default LANG_STRING;
