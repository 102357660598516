import _ from 'lodash';
import {fuzzySort} from '../utils/fuzzy_helper';
import create from 'zustand';
import {postprocessFoodDbRaw} from './food_reducers';
import {produce} from 'immer';

export const getFoodDb = ({foodDb}) => ({foodDb});
export const getFoodSearchResults = ({foodSearchResults}) => ({
  foodSearchResults,
});
const filter_nondeleted = o => o.lectored !== 'DELETED';
const is_capitalized_metrics = o => o?.metrics?.some(o => !(typeof o?.value === 'string') || o.value !== o.value.toUpperCase());
const string_details = o => (!(o.details) || Object.entries(o?.details)?.some(([k, v]) => typeof v === 'string'));
const undefined_details = o => (!(o.details) || Object.entries(o?.details)?.some(([k, v]) => v === undefined));
const undefined_details_value = o => o?.details && Object.entries(o?.details)?.some(([k, v]) => v === undefined);
const nondot_recipe_amount_value = o => o?.recipe && !o.recipe?.some || o.recipe?.some((v) => ('' + v.amount).includes(','));
const print_fid_n_name = o => `${o.id}: ${o.localisations.hu}`;
const dbPrinting_n_checkings = _.once(db => {
  const nonDeletedDb = Object.values(db).filter(filter_nondeleted);
  //Check for string gi values
  // console.log('Stirng GI details', Object.values(db).filter(o => typeof o?.details?.gi === 'string').map(o => o));
  // console.log('String details:', Object.values(db).filter(o => o.details && Object.values(o.details).some(o => typeof o === 'string')).map(o => o.localisations?.hu));
  // console.log('Nonexisting metrics', nonDeletedDb.filter(is_capitalized_metrics).map(print_fid_n_name));
  console.log('String details', nonDeletedDb.filter(string_details).map(print_fid_n_name));
  console.log('Undefined details', nonDeletedDb.filter(undefined_details).map(print_fid_n_name));
  console.log('Undefined details value', nonDeletedDb.filter(undefined_details_value).map(print_fid_n_name));
  console.log('Nondotted recipes amount', nonDeletedDb.filter(nondot_recipe_amount_value).map(print_fid_n_name));

  console.log('>>>>> 0', undefined_details_value(db['']));
  // Check for Nans
  console.log('Nan details:', Object.values(db).filter(o => o.details && Object.values(o.details).some(d => d !== d)).map(print_fid_n_name));
  // console.log('String details:', Object.values(db).filter(o => o.details && Object.values(o.details).some(d => typeof (d) === 'string')).map(print_fid_n_name));
  // console.log('Wrong weight:', Object.values(db).filter(filter_nondeleted).filter(o => o.metrics && Object.values(o.metrics).some(w => typeof (w.weights) === 'string')).map(print_fid_n_name));
  // console.log('Wrong weight:', Object.values(db).filter(o => o.metrics && Object.values(o.metrics).some(w => typeof (w.weights) === 'string')).map(print_fid_n_name));

});
const onInit = (set, get) => async firebase => {
  let {foodDb} = get();
  let newFoodDb = foodDb ?? {};
  firebase
    .firestore()
    .collection('food_db')
    // .where(admin.firestore.FieldPath.documentId(), "==", "01005")
    // .where('m', '>', 1605188691)
    // .where("uid", "==", null)
    .onSnapshot(
      snap => {
        const oldFoodDb = newFoodDb;
        newFoodDb = produce(newFoodDb, draft => {
          snap.docChanges().forEach(function (change) {
            if (change.type === 'added') {
              // console.log('Food added:', change.doc.data());
              draft[change.doc.id] = {
                ...change.doc.data(),
                id: change.doc.id,
              };
            }
            if (change.type === 'modified') {
              console.log('Modified food:', newFoodDb[change.doc.id], '->', change.doc.data());
              draft[change.doc.id] = {
                ...change.doc.data(),
                id: change.doc.id,
              };
            }
            if (change.type === 'removed') {
              console.log('Removed food: ', change.doc.data());
              delete draft[change.doc.id];
            }
          });
        });
        dbPrinting_n_checkings(newFoodDb);
        set(s => ({foodDb: newFoodDb, firebase}));
        const {postprocessFoodDb, optimal} = useFoodCacheDbStore.getState();
        if (optimal === null) {
          const {foodDb} = get();
          postprocessFoodDb(foodDb);
        }
      },
      error => {
        console.log('error', error);
        // ...
      },
    );
  firebase
    .database()
    .ref(`/foodsLectored/`)
    .once('value', snapshot => {
      set(s => ({
        ...s,
        lectorState: snapshot.val(),
      }));
    });
};
const getLectorState = (set, get) => async fid => {
  const {lectorState, firebase} = get();
  if (!firebase) return null;
  if (lectorState.hasOwnProperty(fid)) {
    return lectorState[fid];
  } else {
    console.log('lectorState all', lectorState);
    return await firebase
      .database()
      .ref(`/foodsLectored/${fid}`)
      .once('value', snapshot => {
        console.log('snapshot', snapshot);
        console.log('snapshot', snapshot.val());
        set(s => ({
          ...s,
          lectorState: produce(draft => {
            draft[fid] = snapshot.val();
          })(lectorState),
        }));
      });
  }
};
const setLectorState = (set, get) => (fid, value) => {
  const {lectorState, firebase} = get();
  if (!firebase) return null;
  const update = {status: value};
  firebase.database().ref(`/foodsLectored/${fid}`).update(update);
  set(s => ({
    ...s,
    lectorState: produce(draft => {
      draft[fid] = update;
    })(lectorState),
  }));
};

export const useFoodDbStore = create((set, get) => {
  return {
    language: null,
    foodDb: null,
    lastCheck: 0,
    firebase: null,
    lectorState: {},
    getFoodLectorState: getLectorState(set, get),
    setFoodLectorState: setLectorState(set, get),
    setFoodDb: foodDb => {
      set(s => ({...s, foodDb: foodDb}));
      useFoodCacheDbStore.getState().postprocessFoodDb(foodDb);
    },
    setFood: (id, food) => {
      const {foodDb} = get();
      const newFoodDb = produce(draft => {
        draft[id] = food;
      })(foodDb);
      console.log('newFoodDb', newFoodDb[id], food);
      set(s => ({foodDb: newFoodDb}));
      useFoodCacheDbStore.getState().postprocessFoodDb(foodDb);
    },
    init: onInit(set, get),
  };
});
export const useFoodCacheDbStore = create((set, get) => ({
  optimal: null,
  foodSearchResults: null,
  languages: ['en', 'hu'],
  setLanguages: languages => {
    set({languages});
    const foodDb = useFoodDbStore.getState().foodDb;
    get().postprocessFoodDb(foodDb);
  },
  setFoodSearchResults: results =>
    set(s => ({...s, foodSearchResults: results})),
  postprocessFoodDb: postprocessFoodDbRaw(set, get),
}));

const runIfNotStale = _.throttle(async foods => {
  useFoodCacheDbStore.getState().setFoodSearchResults(foods);
}, 20);
const requestFoodSearchRaw = (phrase, limit, foodCache, dispatch) => {
  if (phrase === '') {
    runIfNotStale(null);
  } else if (foodCache?.optimal) {
    let result = fuzzySort(phrase, foodCache.optimal, [
      'name0',
      'name1',
      'name2',
      'id', // This is not added in application.
    ]);
    const foodDb = useFoodDbStore.getState().foodDb;
    const foods = result
      .slice(0, limit)
      .map(v => ({
        ref: foodDb[v.obj.id],
        indexes: v.indexes,
      }))
      .filter(v => v.indexes.length > 0);
    runIfNotStale(foods);
  } else {
    dispatch(refreshCachedFoodsThrottled);
  }
};
export const requestFoodSearch =
  (phrase, limit = 10) =>
    dispatch => {
      const foodCache = useFoodCacheDbStore.getState();
      requestFoodSearchRaw(phrase, limit, foodCache, dispatch);
    };
// Get food DB if it still hasn't been loaded.
export const requestCachedFoodsRefresh = () => (dispatch, getState) => {
  const foodCache = useFoodCacheDbStore.getState();
  if (!foodCache?.optimal) {
    dispatch(refreshCachedFoodsThrottled);
  }
};

export const refreshCachedFoods = (dispatch, getState, firebase) => {
  useFoodDbStore.getState().init(firebase);
};

export const refreshCachedFoodsThrottled = _.throttle(
  refreshCachedFoods,
  5000,
  {trailing: false},
);

export const requestAllFood = () => dispatch => {
  dispatch(refreshCachedFoodsThrottled);
};

const handleError = msg => err => {
  console.error('Error adding document: ', err);
};
export const addFoodRequest =
  (food, cb) => async (dispatch, getState, firebase) => {
    console.log('food', food);
    const food_db_ref = firebase.firestore().collection('food_db');
    await food_db_ref
      .add({...food, m: new Date().getTime(), lectored: 'USER_LOCAL'})
      .then(doc => {
        console.log('Document written with ID: ', doc.id);
        food_db_ref
          .doc(doc.id)
          .update({id: doc.id})
          .then(doc2 => {
            console.log('Document updated.');
            cb && cb(doc.id);
          })
          .catch(handleError('Add -> Doc update'));
      })
      .catch(handleError('Add -> Doc addition'));
  };
export const updateFoodRequest =
  (food, cb) => (dispatch, getState, firebase) => {
    const food_db_ref = firebase.firestore().collection('food_db');
    food_db_ref
      .doc(food.id)
      .update({...food, m: new Date().getTime()})
      .then(doc => {
        console.log('Document written with ID: ', doc?.id);
        cb && cb();
      })
      .catch(handleError('Update doc.'));
    // const { setFood } = useFoodDbStore.getState(({ setFood }) => ({ setFood }));
    // setFood(food.id, food);
  };
export const addFoodCommentRequest =
  (id, comments, cb) => async (dispatch, getState, firebase) => {
    const food_db_ref = firebase.firestore().collection('food_db');
    const commentRef = await food_db_ref.doc(id).collection('comments').doc();
    console.log('commentRef', commentRef);
    food_db_ref
      .doc(id)
      .update({comments, m: new Date().getTime()})
      .then(doc => {
        console.log('Document written with ID: ', doc?.id);
        cb && cb();
        setTimeout(() => dispatch(refreshCachedFoods), 2000); // wait for server refresh.
      })
      .catch(handleError('Comment update doc.'));
  };
