import React, {useMemo, useState} from 'react';
import {CELL_HEIGHT, column_selector_fns} from '../../utils/food_helper';
import '../../styles/textcell.css';
import filter_icon from '../../assets/filter.svg';
import {PopupContainer} from './PopupContainer';
import {FilterPanel} from '../comparation/FilterPanel';
import ControlledInput from './ControlledInput';

const columnMaxWidths = {ID: 90};
export const TextColumnTitle = React.memo(
  ({item, value, columnKey, style, onPress, editable, dispatch, column, filters}) => {
    let {getter, setter, style: styleBase} = column_selector_fns[columnKey];
    const [filterOpen, setFilterOpen] = useState(false);
    const maxWidth = columnMaxWidths[columnKey];
    const filterOptions = filters?.hasOwnProperty(columnKey) ? filters[columnKey] : {};
    value = value ?? getter(item ?? {});
    const maxWidthStyle = useMemo(
      () =>
        columnKey in columnMaxWidths
          ? {maxWidth}
          : {},
      [columnKey],
    );
    const rowStyle = useMemo(() => ({
      ...styles.defaultStyle,
      flex: 4,
      cursor: onPress ? 'pointer' : 'auto',
      ...maxWidthStyle,
      ...styleBase,
      ...(style ?? {}),
    }), [styleBase, style, maxWidthStyle, onPress]);
    // console.log('item.lectored', item?.lectored, item?.id);
    return (<div style={rowStyle}
                 onClick={() => onPress?.(item, dispatch)}>
        <div style={{
          marginLeft: 6,
          marginRight: -0.5, // the ugly hack
        }}>{value}</div>
        {column &&
          <div src={filter_icon} style={{marginLeft: -18, marginRight: 6, opacity: 0.7}}
               onClick={(e) => {
                 console.log('Open filr');
                 setFilterOpen(true);
                 e.stopPropagation();
               }}>{filterOptions?.enabled ? '▼' : '▽'}</div>}
        {filterOpen &&
          <FilterPanel columnKey={columnKey} onClose={() => {
            setFilterOpen(false);
          }}/>}
      </div>
    );
  },
);

export const TextColumn = React.memo(
  (props) => {
    let {item, value, columnKey, style, onPress, editable, dispatch} = props;
    const {getter, setter, style: styleBase} = column_selector_fns[columnKey];
    const onValueChange = setter ? setter(item?.id, dispatch) : () => 0;
    const maxWidth = columnMaxWidths[columnKey];
    value = value ?? getter(item ?? {});
    const [text, setText] = useState(value);
    const maxWidthStyle = useMemo(
      () =>
        columnKey in columnMaxWidths
          ? {maxWidth}
          : {},
      [columnKey],
    );
    const rowStyle = useMemo(() => ({
      ...styles.defaultStyle,
      flex: 4,
      cursor: onPress ? 'pointer' : 'auto',
      ...maxWidthStyle,
      ...styleBase,
      ...(style ?? {}),
    }), [styleBase, style, maxWidthStyle, onPress]);

    // console.log('item.lectored', item?.lectored, item?.id);
    return (editable ?
        <div
          style={{...rowStyle, borderWidth: 0}}>
          <div style={{
            borderLeft: '0.4px solid #bbb',
            borderRight: '0.4px solid #bbb',
            marginRight: -0.5, // the ugly hack
            flex: 1,
            display: 'flex',
          }}>
            <input
              key={`${columnKey}_${item.id}`}
              className={'excel'}
              style={{
                flex: 1,
                display: 'flex',
                backgroundColor: 'transparent',
                height: CELL_HEIGHT,
                padding: 6,
                width: '100%',
                borderRadius: 0,
              }}
              onClick={() => onPress?.(item, dispatch)}
              value={text}
              onChange={e => {
                console.log('e', e, e.target.selectionStart);
                setText(e.target.value);
                onValueChange(e);
              }}
            />
          </div>
        </div> : <TextColumnTitle {...props}/>
    );
  },
);

const styles = {
  defaultStyle: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
};
