import React from 'react';

class Hovered extends React.Component {
  state = { hovered: false };
  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };
  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };
  render() {
    const { hoverStyle, children } = this.props;
    return (
      <div
        style={{
          ...(this.state.hovered ? hoverStyle : {}),
          cursor: 'pointer',
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {children}
      </div>
    );
  }
}

export default Hovered;
