import {
  ADD_KALORI_FOOD,
  CHANGE_KALORI_FOOD,
  REMOVE_KALORI_FOOD,
  SET_KALORI_FOODS,
} from './foodtrend_actions';
import _ from 'lodash';

export const foods_kalori_reducers = (state = {}, action) => {
  switch (action.type) {
    case SET_KALORI_FOODS:
      const data = action.data.filter(o => o);
      // .map(o => (o.gyakorisag ? o : { ...o, gyakorisag: 0 }));
      return data.reduce((acc, o) => {
        if (o.id) {
          acc[o.id] = o;
        } else {
          console.log('o', o);
        }
        return acc;
      }, {});
    case CHANGE_KALORI_FOOD:
      return {
        ...state,
        ...action.food,
      };
    case ADD_KALORI_FOOD:
      return {
        ...state,
        ...action.food,
      };
    case REMOVE_KALORI_FOOD:
      return _.omit(state, action.id);
    default:
      return state;
  }
};
