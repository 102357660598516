import { HIDE_POPUP, SHOW_POPUP } from './popup_actions';

export function popup_reducers(state = { isOn: false }, action) {
  switch (action.type) {
    case SHOW_POPUP:
      return {
        isOn: true,
        content: action.content,
        onClose: action.onClose,
      };
    case HIDE_POPUP:
      return {
        isOn: false,
      };
    default:
      return state;
  }
}
