import React, { useCallback, useState } from 'react';
import Hovered from './Hovered';
import { green } from '../../utils/colors';
import info from '../../assets/info.png';
import { SeparatorLine } from '../buildingblocks/SeparatorLine';
import { Column } from '../buildingblocks/Layout';
import { connect } from 'react-redux';
import { showPopup } from '../../redux/popup_actions';

const ScoreRowContainer = connect(null, { showPopup })(
  React.memo(
    ({
      isScrolling,
      index,
      updateStatus,
      data,
      foodids,
      foods,
      lang,
      showPopup,
    }) => {
      const { status, md5, url: uri, food_ids } = data;
      const foodNames = foodids.map(id =>
        id in foods ? foods[id].localisations[lang][0] : 'User added, UNKNOWN!',
      );

      return (
        <ScoreRow
          foodNames={foodNames}
          uri={uri}
          id={food_ids}
          md5={md5}
          status={status}
          isScrolling={isScrolling}
          index={index}
          updateStatus={updateStatus}
          showPopup={showPopup}
        />
      );
    },
  ),
);
export { ScoreRowContainer };
const ButtonGreen = ({ text, onPress }) => (
  <Hovered hoverStyle={{ opacity: 0.8 }}>
    <p
      style={{
        margin: 0,
        backgroundColor: green,
        borderRadius: 8,
        marginTop: 4,
        color: 'white',
        padding: 4,
        fontSize: 13,
      }}
      onClick={onPress}
    >
      {text}
    </p>
  </Hovered>
);
const SCORE_ROW_WIDTH = 640;
const ScoreRow = React.memo(
  ({
    score,
    index,
    style,
    id,
    uri,
    md5,
    status,
    updateStatus,
    isScrolling,
    foodNames,
    showPopup,
  }) => {
    const [state, setState] = useState({ imgStatus: 'EMPTY', status });
    const { imgStatus, status: localState } = state;
    const onDeletePress = useCallback(() => {
      const nextState = localState === 'DELETED' ? 'RESTORE' : 'DELETED';
      setState({ status: nextState });
      setTimeout(
        () =>
          updateStatus(md5, {
            status: nextState,
            m: parseInt(new Date().getTime() / 1000),
          }),
        10,
      );
    }, [localState]);
    // console.log('status', status);

    return (
      <div
        style={{
          ...style,
          padding: 4,
          maxWidth: SCORE_ROW_WIDTH,
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          ...(localState && localState === 'DELETED' ? { opacity: 0.5 } : null),
        }}
      >
        <p
          style={{
            position: 'absolute',
            top: 4,
            marginLeft: 4,
            color: 'white',
          }}
        >
          {index}.
        </p>
        {isScrolling && imgStatus === 'EMPTY' ? null : (
          <img
            src={imgStatus === 'ERROR' ? info : uri}
            alt=""
            style={{ height: 160, width: 160 }}
            onError={() =>
              imgStatus === 'EMPTY' && setState({ imgStatus: 'ERROR' })
            }
            onLoad={() =>
              imgStatus === 'EMPTY' && setState({ imgStatus: 'LOADED' })
            }
          />
        )}
        <Column>
          {foodNames &&
            foodNames.map((name, i) => (
              <div key={i} style={{ width: '100%' }}>
                <div>{name}</div>
                <SeparatorLine />
              </div>
            ))}
          <div>
            {/*<ButtonGreen*/}
            {/*onPress={onDeletePress}*/}
            {/*text={localState === 'DELETED' ? 'RESTORE' : 'DELETE'}*/}
            {/*/>*/}
            <ButtonGreen
              onPress={() => {
                showPopup('search');
              }}
              text={'ADD FOOD'}
            />
          </div>
        </Column>
      </div>
    );
  },
);

const styles = {
  default: {
    display: 'flex',
    flex: 1,
    fontSize: 17,
  },
};
export default ScoreRow;
