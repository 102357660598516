import React from 'react';
import { connect } from 'react-redux';
import { closePopup } from '../redux/popup_actions';

class Popup extends React.PureComponent {
  render = () => {
    const { onClose, isOn, closePopup, content } = this.props;
    return isOn ? (
      <div
        style={styles.fullScreen}
        onClick={() => {
          onClose && onClose();
          closePopup();
        }}
      >
        <div
          style={{
            ...styles.fullScreen,
            backgroundColor: 'black',
            opacity: 0.5,
          }}
          onClick={() => {
            onClose && onClose();
            closePopup();
          }}
        />
        <div
          style={{
            ...styles.fullScreen,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'white',
              borderRadius: 8,
              zIndex: 1,
              padding: 20,
            }}
          >
            {content()}
          </div>
        </div>
      </div>
    ) : null;
  };
}

const styles = {
  fullScreen: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export default connect(
  state => ({
    isOn: state.popup.isOn,
    content: state.popup.content,
    onClose: state.popup.onClose,
  }),
  {
    closePopup,
  },
)(Popup);
