import React from 'react';
import {connect} from 'react-redux';
import {selectOrderColumn} from '../../redux/actions';
import {column_selector_fns} from '../../utils/food_helper';
import {TextColumnTitle} from '../buildingblocks/TextCell';
import {useColumnStore} from './ColumnOrder';

const FoodRowColumn = React.memo(props => {
  const {setSortFn, reverses, sortedColumns, selectedColumns, filters} =
    useColumnStore(
      ({setSortFn, sortedColumns, reverses, selectedColumns, filters}) => ({
        setSortFn,
        sortedColumns,
        reverses,
        selectedColumns,
        filters,
      }),
    );
  return (
    <div
      style={{
        width: '100%',
        zIndex: 1000,
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          height: 50,
          justifyContent: 'space-around',
          borderTop: 'solid',
          borderTopColor: '#ccc',
          borderTopWidth: 1,
          borderBottom: 'solid',
          borderBottomColor: '#ccc',
          borderBottomWidth: 1,
        }}
      >
        <div
          style={{
            ...styles.defaultStyle,
            flex: 0,
            minWidth: 56,
            justifyContent: 'flex-end',
            ...styles.borderMarginStyle,
            cursor: 'pointer',
          }}
          onClick={() => setSortFn(null)}
        >
          index
        </div>
        {selectedColumns.map(columnKey => {
          if (columnKey in column_selector_fns) {
            const {name, style} = column_selector_fns[columnKey];
            const columnIndex = sortedColumns.findIndex(c => c === columnKey);
            const columnIndexText =
              columnIndex !== -1
                ? `\n${columnIndex + 1}${reverses[columnIndex] ? '↑' : '↓'}`
                : '';

            return (
              <TextColumnTitle
                key={columnKey}
                columnKey={columnKey}
                value={name + columnIndexText}
                onPress={() => setSortFn(columnKey)}
                filters={filters}
                style={{
                  ...styles.borderMarginStyle,
                  ...(columnIndex !== -1 ? {backgroundColor: '#f0f7Fb'} : {}),
                }}
                editable={false}
                column
              />
            );
          }
          return null;
        })}
        {
          <div
            style={{
              ...styles.defaultStyle,
              ...styles.borderMarginStyle,
              minWidth: 90,
            }}
          >
            Button
          </div>
        }
        <div style={{width: 15}}/>
        {/* scrollbar placeholder */}
      </div>
    </div>
  );
});

const styles = {
  defaultStyle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
  borderMarginStyle: {
    borderRight: 'solid',
    borderRightColor: '#ddd',
    borderRightWidth: 0.4,
  },
};

const mapStateToProps = (state, ownProps) => {
  const {columns} = state.selected;
  return {
    selectedColumns: columns,
  };
};

const mapDispatchToProps = {
  selectOrderColumn,
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodRowColumn);
