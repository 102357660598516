import React from 'react';
import _ from 'lodash';
import List from 'react-virtualized/dist/es/List';
import { ScoreRowContainer } from './ScoreRow';
import { green } from '../../utils/colors';

const RecogTrendHeader = ({
  scrollInsensitivity,
  handleChange,
  switchLang,
  onChangeIndex,
  jumpIndex,
}) => (
  <div style={{ alignItems: 'center' }}>
    <h2
      style={{
        paddingBottom: 16,
        paddingLeft: 24,
        paddingTop: 16,
        marginBottom: 0,
        backgroundColor: green,
        color: 'white',
      }}
    >
      Amount Prediction
    </h2>
    <div
      style={{
        position: 'absolute',
        top: 20,
        color: 'white',
        right: 24,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      Indexhez ugrás:
      <input
        style={{ width: 100, marginRight: 8, borderWidth: 0, marginLeft: 8 }}
        type={'number'}
        value={jumpIndex}
        onChange={onChangeIndex}
      />
      <div
        style={{
          backgroundColor: 'white',
          padding: 6,
          paddingLeft: 12,
          paddingRight: 12,
          borderRadius: 8,
          marginLeft: 12,
          marginRight: 12,
          color: 'black',
        }}
        onClick={switchLang}
      >
        Switch lang
      </div>
      Görgetés érzékenység:
      <input
        style={{ width: 40, borderWidth: 0, marginLeft: 8 }}
        type={'number'}
        value={scrollInsensitivity}
        onChange={handleChange}
      />
    </div>
  </div>
);

export default class RecogTrend extends React.PureComponent {
  state = {
    width: window.innerWidth,
    height: window.innerHeight,
    scrollInsensitivity: 1,
    lang: '101',
  };

  componentDidMount() {
    this.props.initRecogtrend();
    window.addEventListener('resize', e => {
      let width = e.target.outerWidth - 16;
      let height = e.target.outerHeight - 71;
      this.setState({ height, width });
    });
  }

  handleChange = e => {
    const value = e.currentTarget.value;
    this.setState({ scrollInsensitivity: value });
    // console.log(`onChange fired with value: '${}'`);
  };

  onChangeIndex = e => {
    const value = e.currentTarget.value;
    // const sensitivity = ((((100 / 11) * 10000) / 9990) * 40000) / 40002; // 9.09955
    const sensitivity = 9.1; // 9.09955
    if (this.scrollRef)
      this.scrollRef.scrollTop =
        value * sensitivity * this.state.scrollInsensitivity;
    this.setState({ scrollTop: value * sensitivity });
  };

  switchLang = () => {
    this.setState({ lang: this.state.lang === '101' ? '234' : '101' });
  };

  render = () => {
    const {
      scores,
      height,
      width,
      scrollTop,
      isScrolling,
      scrollInsensitivity,
      lang,
      jumpIndex,
    } = this.state;

    const { photos, foods, updateStatus } = this.props;
    let data = photos ? photos : null;
    if (_.size(foods) === 0 || _.size(data) === 0) {
      return (
        <div>
          <RecogTrendHeader
            jumpIndex={jumpIndex}
            onChangeIndex={this.onChangeIndex}
            handleChange={this.handleChange}
            scrollInsensitivity={scrollInsensitivity}
            switchLang={this.switchLang}
          />
          <div style={{ paddingLeft: 24, marginTop: 8 }}>
            Downloading database...
          </div>
        </div>
      );
    }
    const styles = {
      transform: `translate(${0}px, ${-scrollTop *
        (1 - 0.25 * scrollInsensitivity)}px)`,
    };

    return (
      <div>
        <RecogTrendHeader
          jumpIndex={jumpIndex}
          onChangeIndex={this.onChangeIndex}
          handleChange={this.handleChange}
          scrollInsensitivity={scrollInsensitivity}
          switchLang={this.switchLang}
        />
        <div
          style={{
            overflow: 'auto',
            height: height - 70,
            marginTop: 0,
          }}
          ref={ref => (this.scrollRef = ref)}
          onScroll={e => {
            this.setState({
              scrollTop: (e.target.scrollTop * 4) / scrollInsensitivity,
              isScrolling: true,
            });
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(
              () => this.setState({ isScrolling: false }),
              20,
            );
          }}
        >
          <List
            style={styles}
            autoHeight={true}
            // scrollToIndex={scrollTop ? scrollTop : 0}
            scrollTop={scrollTop ? scrollTop : 0}
            height={height}
            ref={'List'}
            width={width - 32}
            // height={height - 120}
            rowHeight={168}
            rowCount={data ? data.length : 0}
            rowRenderer={({ index, style }) => {
              const { url: uri, food_ids: food_ids0, md5 } = data[index][0];
              const { food_ids: food_ids1 } = data[index][1];

              return (
                <div
                  key={md5}
                  style={{
                    ...style,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <ScoreRowContainer
                    isScrolling={isScrolling}
                    foodids={food_ids0}
                    index={index * 2 + 1}
                    updateStatus={updateStatus}
                    data={data[index][0]}
                    lang={lang}
                    foods={foods}
                  />
                  <div
                    style={{
                      width: 0.4,
                      backgroundColor: 'silver',
                      height: '100%',
                      float: 'left',
                    }}
                  />
                  <ScoreRowContainer
                    isScrolling={isScrolling}
                    foodids={food_ids1}
                    index={index * 2 + 2}
                    updateStatus={updateStatus}
                    data={data[index][1]}
                    lang={lang}
                    foods={foods}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  };
}
