import _ from 'lodash';
import {selectFoodID} from './actions';
import {langMap} from '../utils/countries';
import {getEngName, getGerName, getHunName} from '../utils/food_helper';
import {unitEnumToName} from '../utils/enum_helper';
import {addFoodRequest, updateFoodRequest, useFoodDbStore} from './food_actions';
import produce from 'immer';
import {DELETED, HALFLECTORED} from '../utils/lector_helper';
import {getUnixTime} from 'date-fns';

export const SET_KALORI_FOODS = 'SET_KALORI_FOODS';
export const CHANGE_KALORI_FOOD = 'CHANGE_KALORI_FOOD';
export const ADD_KALORI_FOOD = 'ADD_KALORI_FOOD';
export const REMOVE_KALORI_FOOD = 'REMOVE_KALORI_FOOD';

function isLetter(str) {
  return str.length === 1 && str.match(/[a-záéúőóüűö]/i);
}

const getFoodName = ([k, v]) => v.localisations['101'] && v.localisations['101'][0];
const getEngFoodName = ([k, v]) => v.localisations['234'][0];
const getGerFoodName = ([k, v]) => v.localisations[langMap['Germany']] && v.localisations[langMap['Germany']][0];
const isNotDeleted = ([k, v]) => !(v.lectored && v.lectored === 'DELETED');
const getSameNameFoods = (data, name_fn) => {
  const common_foods = {};
  console.log(Object.entries(data)
    .filter(isNotDeleted)
    .filter(([k, v]) => {
      if (name_fn(v) in common_foods) {
        return [k, v];
      }
      common_foods[name_fn(v)] = v;
      return null;
    })
    .filter(o => o && name_fn(o[1]) !== '' && name_fn(o[1]) !== 'Missing name')
    .map(o => name_fn(o[1])));
};
const csv_printings = data => {
  const details_categories = Object.entries(data).reduce((acc, [k, v]) => {
    v.details !== null && v.details !== undefined && Object.keys(v.details).map(k2 => {
      if (!acc.includes(k2)) {
        acc.push(k2);
      }
    });
    return acc;
  }, []);
  console.log('Details categories:', details_categories);
  const csv_file = Object.entries(data).reduce((acc, [k, v]) => {
    const name = getFoodName([k, v]);
    return [...acc, [v.id, name ? name.replace('\n', '') : name, v.lectored, ...details_categories.map(cat => v.details !== null && v.details !== undefined && v.details[cat] ? v.details[cat] : '')]];
  }, [['id', 'name', 'lectored', ...details_categories]]);
  console.log(csv_file.reduce((acc, line) => acc + '\n' + line.join('; '), ''));
};
const dbPrintings = data => {
  // csv_printings(data);

  console.log('Database filterings:');
  // No calorie or data.
  console.log(Object.entries(data)
    .filter(([k, v]) => ['carbohydrate', 'protein', 'fat', 'calorie'].some(key => v.details === null || v.details === undefined || v.details[key] === null || v.details[key] === undefined))
    .filter(isNotDeleted)
    .map(getFoodName));
  // Uncia van vagy nincsen unit.
  console.log(Object.entries(data)
    .filter(([k, v]) => v.metrics === undefined || ['oz'].some(key => v.metrics.some(o => o.value === key)))
    .filter(isNotDeleted)
    .map(getFoodName));
  console.log(Object.entries(data)
    .filter(isNotDeleted)
    .filter(([k, v]) => v.metrics === undefined || v.metrics.some(o => !['100 G', ...Object.keys(unitEnumToName)].includes(o.value.toUpperCase())))
    .map(getFoodName));
  console.log('SZÓKÖZ NEVEK', Object.entries(data)
    .filter(isNotDeleted)
    .map(getGerFoodName)
    .filter(v => v && !isLetter(v.slice(-1)) && v.slice(-1) !== ')' && v.slice(-1) !== '%'));
  getSameNameFoods(data, getHunName);
  getSameNameFoods(data, getEngName);
  getSameNameFoods(data, getGerName);
};

export const loginDiabTrendDb = (email, password) => (dispatch, getState, firebase) => {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(err => {
      console.log('We are creating the unexistent account. Error: ', err.message);
      // foodtrend.auth().createUserWithEmailAndPassword(user.email, user.password)
      //     .catch(err => console.error(err.message));
    });
};
export const signoutFoodTrend = () => (dispatch, getState, firebase) => {
  firebase.auth().signOut();
};
export const newFoodKalori = () => (dispatch, getState, firebase) => {
  const {kaloriFoods} = getState();
  const id = _.max(Object.keys(kaloriFoods).map(o => Number(o))) + 1;
  // id = 'kalori_internal/foodtrend_id_seq/' : 300 000 000
  console.log('id', id);
  const food = {
    localisations: {
      en: ['Unnamed meal'],
    },
    id,
    lectored: HALFLECTORED,
    uid: 'FOODTREND_ID',
    created_at: new Date().getTime(),
    updated_at: new Date().getTime(),
    m: new Date().getTime(),
    details: {},
    metrics: [],
  };
  dispatch(addFoodRequest(food, id => dispatch(selectFoodID(id))));
};

export const changeFoodKaloriFinalID = (id, value) => (dispatch, getState, firebase) => {
  console.warn('UNIMPLEMENTED, USDA_ID change.');
};
export const changeFoodFluidity = (id, value) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const newFood = produce(draft => {
    draft.fluid = value;
    draft.m = new Date().getTime();
  })(foodDb[id]);
  dispatch(updateFoodRequest(newFood));

  // dispatch({ type: CHANGE_KALORI_FOOD, food: { [id]: newFood } });
  // dbChangeFoodFluidity(firebase, id, newFood, food, user);
};

export const deleteFoodKaloriID = id => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  if (foodDb[id].uid === 'FOODTREND_ID') {
    let r = window.confirm('This is a foodtrend food, not user added, are you sure you want to delete it, usually it is not advised to do so!');
    if (r === true) {
      console.log('OK YES.');
      dispatch(changeFoodLectored(id, DELETED));
    } else {
      console.log('OK no.');
    }
  } else {
    dispatch(changeFoodLectored(id, DELETED));
  }
  // dbDeleteFood(firebase, id, newFood, food, user);
};
const checkIfObj = (obj) => obj && !_.isArray(obj) ?
  Object.entries(obj).reduce((acc, [k, v]) => {
    acc.push({id: k, ...v});
    return acc;
  }, []) :
  obj;
export const removeFoodRecipe = (id, index) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];
  const newFood = produce(draft => {
    draft.recipe = checkIfObj(draft.recipe);
    draft.recipe.splice(index, 1);
    draft.m = new Date().getTime();
  })(food);
  dispatch(updateFoodRequest(newFood));
};
export const moveFoodRecipeOrder = (id, fromIdx, toIdx) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];
  const newFood = produce(draft => {
    draft.recipe = checkIfObj(draft.recipe);
    const item = draft.recipe[fromIdx];
    draft.recipe.splice(fromIdx, 1);
    draft.recipe.splice(toIdx, 0, item);
    draft.m = new Date().getTime();
  })(food);
  dispatch(updateFoodRequest(newFood));
};
export const updateFoodRecipe = (id, update, index) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];
  const newFood = produce(draft => {
    if (!draft.hasOwnProperty('recipe')) {
      draft.recipe = [];
      if (!draft.hasOwnProperty('others')) {
        draft.others = {};
      }
      if (!draft.others.hasOwnProperty('created')) {
        draft.others.created = new Date().getTime();
      }
    }
    draft.recipe = checkIfObj(draft.recipe);
    const indexOf = draft.recipe.find(o => o.id === update.id);
    // console.log('indexOf', indexOf, index, index === indexOf);
    if (index !== null && index !== undefined) {
      draft.recipe[index] = {...draft.recipe[index], ...update};
    } else {
      draft.recipe.push(update);
    }
    draft.m = new Date().getTime();
  })(food);
  dispatch(updateFoodRequest(newFood));
};
export const toggleFoodTag = (id, tag) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];
  const newFood = produce(draft => {
    if (!draft.hasOwnProperty('tags')) {
      draft.tags = [];
    }

    if (draft.tags.includes(tag)) {
      draft.tags = draft.tags.filter(t => t !== tag);
    } else {
      draft.tags.push(tag);
    }
    draft.m = new Date().getTime();
  })(food);
  // console.log('newFood', newFood);
  dispatch(updateFoodRequest(newFood));
};
export const updateFoodOthers = (id, key, value) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];

  const newFood = produce(draft => {
    if (value === null || value === '') {
      delete _.get(draft, key);
    } else {
      if (!draft.hasOwnProperty('others')) {
        draft.others = {};
      }
      if (!draft.others.hasOwnProperty('created')) {
        draft.others.created = new Date().getTime();
      }
      _.set(draft, key, value);
    }
    draft.m = new Date().getTime();
  })(food);
  dispatch(updateFoodRequest(newFood));
};
export const changeFoodContains = (id, key, value) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];

  const newFood = produce(draft => {
    if (value === null) {
      delete draft.details[key];
    } else {
      if (!draft.details) {
        draft.details = {};
      }
      draft.details[key] = value;
    }
    draft.m = new Date().getTime();
  })(food);
  dispatch(updateFoodRequest(newFood));
  // dispatch({ type: CHANGE_KALORI_FOOD, food: { [id]: newFood } });
  // dBSetFoodWithTypeName(firebase, id, newFood, food, user, 'containsChange');
};

export const updateFoodContains = (id, update) => (dispatch, getState, firebase) => {
  if (update === null) {
    return;
  }
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];
  const newFood = produce(draft => {
    draft.details = {...draft.details, ...update};
    draft.m = new Date().getTime();
  })(food);
  dispatch(updateFoodRequest(newFood));
};
export const deleteFoodComment = (id, comment) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];
  const newFood = {
    ...food, comments: food.comments.filter(c => !(c.id === comment.id || c.timestamp === comment.timestamp)), m: new Date().getTime(),
  };
  console.log('id, comment', id, comment);
  dispatch(updateFoodRequest(newFood));
  // dispatch({ type: CHANGE_KALORI_FOOD, food: { [id]: newFood } });
  // dispatch({ type: REMOVE_KALORI_FOOD, id });
  // dBSetFoodWithTypeName(firebase, id, newFood, food, user, 'commentChange');
};
export const changeFoodServings = (id, key, value) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];
  let newFood = null;
  if (value === null) {
    newFood = {
      ...food, metrics: food.metrics.filter(o => o.value !== key),
    };
  } else {
    const unit = food.metrics?.find(o => o.value === key);
    if (unit !== undefined) {
      const newUnit = {...unit, weights: value};
      if (unit.weights === value) {
        return;
      }
      newFood = {
        ...food, metrics: food.metrics.map((o, index) => o.value === key ? newUnit : o),
      };
    } else {
      const unit = {value: key, weights: value};
      newFood = {
        ...food, metrics: food.metrics ? [...food.metrics, unit] : [unit],
      };
    }
  }
  newFood = {...newFood, m: new Date().getTime()};
  dispatch(updateFoodRequest(newFood));
  // dispatch({ type: CHANGE_KALORI_FOOD, food: { [id]: newFood } });
  // dBSetFoodWithTypeName(firebase, id, newFood, food, user, 'servingsChange');
};


export const changeFoodName = (id, name, countryName, idx = 0) => (dispatch, getState, firebase) => {
  const {user} = getState();
  const {foodDb} = useFoodDbStore.getState();
  const food = foodDb[id];
  name = name.trim();

  const newFood = produce(draft => {
    if (!food.localisations.hasOwnProperty(countryName) || food.localisations[countryName]?.length === 0) {
      draft.localisations[countryName] = [name];
    } else if (draft.localisations[countryName].length <= idx) {
      draft.localisations[countryName].push(name);
    } else if (draft.localisations[countryName]?.length > idx && name === '') {
      draft.localisations[countryName].splice(idx, 1);
    } else {
      draft.localisations[countryName][idx] = name;
    }
    draft.m = new Date().getTime();
  })(food);
  console.log('newFood:', newFood);
  dispatch(updateFoodRequest(newFood));
  // dispatch({ type: CHANGE_KALORI_FOOD, food: { [id]: newFood } });
  // dBChangeFoodName(firebase, id, newFood, food, user, changeType);
};
export const changeFoodKaloriComment = (id, comment) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const newFood = {...foodDb[id], comment};
  console.log('newFood', newFood);
  dispatch(updateFoodRequest(newFood));
  // dispatch({ type: CHANGE_KALORI_FOOD, food: { [id]: newFood } });
  // dbChangeFoodComment(firebase, id, newFood, food, comment, user);
};
export const changeFoodLectored = (id, lectored) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const newFood = {...foodDb[id], lectored};
  console.log('newFood:', newFood);
  dispatch(updateFoodRequest(newFood));
  // dispatch({ type: CHANGE_KALORI_FOOD, food: { [id]: newFood } });
  // dBSetFoodWithTypeName(firebase, id, newFood, food, user, "lectoreChange");
};
export const changeFoodSrc = (id, src) => (dispatch, getState, firebase) => {
  const {foodDb} = useFoodDbStore.getState();
  const newFood = {...foodDb[id], src};
  console.log('newFood:', newFood);
  dispatch(updateFoodRequest(newFood));
};
const uidToEmails = {
  '1Buznx3WJibdAHre2k6xj7unYgk2': 'make.food.great.again@diabtrend.com',
  '6ttTMqdmLlaOgdA43xyA278hIAf2': 'plavec.andrea@gmail.com',
  KRWaKWWVeLT160fxTBj0lKGIKri1: 'anita1@foodtrend.com',
  '7FLkxB0GARakVLA571Guho8QDCy2': 'havlikkaresz@gmail.com',
  FfTtY2CurIdwwM7CVfEv4wu4g7d2: 'boss@foodtrend.world',
  przblOkOHpMKMdPaujw6xWQPmZc2: 'havliktomi@gmail.com',
};
const getKey = (acc, key, defVal) => (key in acc ? acc[key] : defVal);
export const getStats = () => (dispatch, getState, firebase) => {
  firebase
    .database()
    .ref('/logs')
    .once('value', snapshot => {
      const scoresKeys = Object.entries(snapshot.val()).reduce((acc, [k, foodChanges]) => ({
        ...acc, ...Object.values(foodChanges).reduce((acc2, change) => {
          const {uid, type} = change;
          return {...acc2, [uid + '/' + type + '/' + k]: 1};
        }, {}),
      }), {});
      const scores = Object.keys(scoresKeys).reduce((acc, key) => {
        const proKey = key.split('/')[0];
        const type = key.split('/')[1];
        const innerVal = getKey(acc, proKey, {});
        const innerValForType = getKey(innerVal, type, 0);
        return {
          ...acc, [proKey]: {...innerVal, [type]: innerValForType + 1},
        };
      }, {});
      alert('Pontszámok a konzolban láthatóak.');
      console.log('Pontszámok:', scores);
      console.log(Object.entries(scores).map(([key, val]) => getKey(uidToEmails, key, key) + ' \n' + Object.entries(val)
        .map(([key, val]) => '   ' + key + ': ' + val)
        .join('\n')));
    });
};
