import {INVALID, UNVALIDATED, VALID} from './constants';
import unorm from 'unorm';
import {proParseFloat} from './dtjs/parsers';

export const EMPTY_STR = '';

export function toggle_flag(flag) {
  return flag ? !flag : true;
}

export function toggle_validated(flag) {
  if (!flag || flag === UNVALIDATED) {
    return VALID;
  }
  if (flag === VALID) {
    return INVALID;
  } else if (flag === INVALID) {
    return VALID;
  }
}

export const isNan = value => {
  if (value === '') {
    return true;
  }
  const number = proParseFloat(value);
  return number !== number;
};

export const replaceAccentedChars = str =>
  unorm.nfd(str).replace(/[\u0300-\u036f]/g, '');
