import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

const rootNode = document.getElementById('root');
// ReactDOM.createRoot(rootNode).render(<Root />);
// ReactDOM.unstable_createRoot(rootNode).render(<Root/>);
ReactDOM.render(<Root/>, rootNode);
