import {
  LECTORED,
  } from './lector_helper';
import {
  DELETED,
  HALFLECTORED,
  MODIFIED,
  UNLECTORED,
  USDA_LECTORED,
  USER_LOCAL,
  USER_LOCAL_CHECKED
} from "./lector_helper";

export const extraAssets = {
  lectored: require('../assets/lectored.png'),
  halfChecked: require('../assets/halfChecked.png'),
  usdaFit: require('../assets/usdaFit.png'),
  userAdded: require('../assets/userAdded.png'),
  userModified: require('../assets/userModified.png'),
  deleted: require('../assets/delete.svg'),
  userLocal: require('../assets/red-head.png'),
  userLocalChecked: require('../assets/gray-head.png'),
};

export const lectoredMap = {
  [DELETED]: extraAssets.deleted,
  [LECTORED]: extraAssets.lectored,
  [MODIFIED]: extraAssets.userModified,
  [USDA_LECTORED]: extraAssets.usdaFit,
  [HALFLECTORED]: extraAssets.halfChecked,
  [UNLECTORED]: extraAssets.userAdded,
  [USER_LOCAL]: extraAssets.userLocal,
  [USER_LOCAL_CHECKED]: extraAssets.userLocalChecked,
};
export const lectoredNameMap = {
  [UNLECTORED]: 'Unlectored',
  [USDA_LECTORED]: 'USDA lectored',
  [HALFLECTORED]: 'Partly lectored',
  [MODIFIED]: 'User modified',
  [LECTORED]: 'Lectored',
  [USER_LOCAL]: 'User local',
  [USER_LOCAL_CHECKED]: 'Checked user local ',
};
