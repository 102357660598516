// Shameless copy from stackoverflow: https://stackoverflow.com/questions/43692479/how-to-upload-an-image-in-react-js
import React, {useEffect, useState} from 'react';

/**
 * @param {string} url - The source image
 * @param {number} aspectRatio - The aspect ratio
 * @return {Promise<HTMLCanvasElement>} A Promise that resolves with the resulting image as a canvas element
 * Code from: https://pqina.nl/blog/cropping-images-to-an-aspect-ratio-with-javascript/
 */
function crop(url, aspectRatio) {
  // we return a Promise that gets resolved with our canvas element
  return new Promise((resolve) => {
    // this image will hold our source image data
    const inputImage = new Image();

    // we want to wait for our image to load
    inputImage.onload = () => {
      // let's store the width and height of our image
      const inputWidth = inputImage.naturalWidth;
      const inputHeight = inputImage.naturalHeight;

      // get the aspect ratio of the input image
      const inputImageAspectRatio = inputWidth / inputHeight;

      // if it's bigger than our target aspect ratio
      let outputWidth = inputWidth;
      let outputHeight = inputHeight;
      if (inputImageAspectRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio;
      } else if (inputImageAspectRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio;
      }

      // calculate the position to draw the image at
      const outputX = (outputWidth - inputWidth) * 0.5;
      const outputY = (outputHeight - inputHeight) * 0.5;

      // create a canvas that will present the output image
      const outputImage = document.createElement('canvas');

      // set it to the same size as the image
      outputImage.width = outputWidth;
      outputImage.height = outputHeight;

      // draw our image at position 0, 0 on the canvas
      const ctx = outputImage.getContext('2d');
      ctx.drawImage(inputImage, outputX, outputY);
      resolve(outputImage);
    };

    // start loading our image
    inputImage.src = URL.createObjectURL(url);
  });
}

const UploadAndDisplayImage = ({onSelect}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [success, setSuccess] = useState(null);
  useEffect(() => {
    selectedImage && crop(selectedImage, 4 / 3).then(o => {
      const data_url = o.toDataURL('image/jpeg');
      onSelect && onSelect(data_url, () => setSuccess(true));
      setSelectedImageData(data_url);
    });
  }, [selectedImage]);
  return (
    <div>
      <h1>Upload Image</h1>
      {selectedImage && (
        <div>
          <img alt="not fount" width={'360px'} src={selectedImageData ?? URL.createObjectURL(selectedImage)}/>
          <br/>
          <button onClick={() => {
            setSelectedImage(null);
            setSelectedImageData(null);
            setSuccess(null);
          }}>Remove
          </button>
          {success === 'IN_PROGRESS' ? <div style={{color: 'green'}}>Upload in progress...</div> :
            success ? <div style={{color: 'green'}}>Uploaded successfully</div> : null}
        </div>
      )}
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          setSelectedImage(event.target.files[0]);
          setSuccess('IN_PROGRESS');
        }}
      />
    </div>
  );
};

export default UploadAndDisplayImage;
