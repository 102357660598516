import React, {useCallback, useState} from 'react';
import {useFoodDbStore} from '../../redux/food_actions';
import {CELL_HEIGHT, getCh, getFat, getFoodName, getProtein} from '../../utils/food_helper';
import {
  changeFoodContains,
  getStats,
  moveFoodRecipeOrder,
  newFoodKalori,
  removeFoodRecipe,
  updateFoodOthers,
  updateFoodRecipe,
} from '../../redux/foodtrend_actions';
import {ReactComponent as Cancel} from '../../assets/cancel.svg';
import {ReactComponent as DownArrow} from '../../assets/up-arrow.svg';
import _ from 'lodash';
import {Button, ButtonGroup, Dropdown} from 'react-bootstrap';
import {getAllMetricsForFood} from '../../utils/unit_helpers';
import {darkBackground} from '../../utils/colors_helper';
import {useDispatch} from 'react-redux';
import {useWindowSize} from '../../utils/diamonds';
import ControlledInput, {ControlledTextarea} from '../buildingblocks/ControlledInput';
import UploadAndDisplayImage from '../buildingblocks/UploadAndDisplayImage';
import {storage} from '../../firebase/diabtrend-db';
import {VictoryLabel, VictoryPie} from 'victory';
import MealSummary from './MealSummary';
import {green} from '../../utils/colors';
import {blue} from '@material-ui/core/colors';
import {getRecipeFullWeight, getRecipeWeight} from '../../utils/dtjs/recipes_core';
import {getRecipeContain} from '../../utils/dtjs/recipes';
import {proParseFloat} from '../../utils/dtjs/parsers';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const Textarea = (props) => <ControlledTextarea {...props}/>;
const InputElement = (props) => <ControlledInput {...props}/>;

const InputOthers = (props) => {
  const dispatch = useDispatch();
  const {height, width} = useWindowSize();
  const {refFood, title, field, unit, isTextarea, isNumber} = props;
  const [text, setText] = useState(_.get(refFood, field, ''));
  const debouncedFieldChanger = useCallback(_.debounce((value) => {
    dispatch(updateFoodOthers(refFood.id, field, isNumber ? proParseFloat(value) : value));
  }, 1000), [refFood.id, field]);
  const Component = isTextarea ? Textarea : InputElement;
  return <div style={{marginTop: 4, marginBottom: 4, display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
    <div style={{marginBottom: 4, marginRight: 20}}>
      {title}
    </div>
    <div style={{marginBottom: 4, marginRight: 20, display: 'flex', flex: 1, flexDirection: 'row-reverse', alignItems: 'center'}}>
      {unit && <div style={{marginLeft: 4}}>
        {unit}
      </div>}
      <Component
        type="textarea"
        style={{
          width: isTextarea ? '100%' : 100,
          display: 'flex',
          padding: 6,
          borderRadius: 0,
          backgroundColor: darkBackground,
          borderWidth: 0,
        }}
        rows={3}
        // onClick={() => onPress?.(item, dispatch)}
        value={text ?? ''}
        onChange={e => {
          console.log('e', e);
          setText(e.target.value);
          debouncedFieldChanger(e.target.value);
          // setText(e.target.value);
          // onValueChange(e);
        }}
      />
    </div>
  </div>;
};
const ServingRow = ({foods, rid, refFood, r, index, isEnd, isFirst}) => {
  const dispatch = useDispatch();
  const [text, setText] = useState(r.amount ?? '');

  return <div style={{
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
  }}>
    <div style={{flex: 1}}>
      <div style={{display: 'flex', flex: 1, color: r.hidden ? 'gray' : 'black'}}>
        • {foods?.[rid] && getFoodName(foods?.[rid], true)}
      </div>
      <div style={{display: 'flex', flex: 1, color: 'gray', marginLeft: 20, alignItems: 'center'}}>
        Ch:{(getCh(foods?.[rid].details) * r.amount / 100 * r.weights).toFixed(0)} -
        P:{(getProtein(foods?.[rid].details) * r.amount / 100 * r.weights).toFixed(0)} -
        F:{(getFat(foods?.[rid].details) * r.amount / 100 * r.weights).toFixed(0)}
        <div style={{
          display: 'flex', flex: 1, color: r.hidden === true ? blue['500'] : 'gray', marginLeft: 20, fontSize: 11, cursor: 'pointer', fontWeight: 'bold',
        }} onClick={() => {
          dispatch(updateFoodRecipe(refFood.id, {id: rid, hidden: !r.hidden}, index));
        }}>
          {r.hidden === true ? 'SHOW' : 'HIDE'}
        </div>
      </div>

    </div>
    <input
      style={{
        display: 'flex',
        maxWidth: 80,
        height: CELL_HEIGHT,
        padding: 6,
        borderRadius: 0,
        backgroundColor: darkBackground,
        borderWidth: 0,
      }}
      value={text}
      onChange={e => {
        console.log('e', e);
        dispatch(updateFoodRecipe(refFood.id, {id: rid, amount: proParseFloat(e.target.value)}, index));
        setText(e.target.value);
      }}
    />
    {foods?.[rid]?.metrics && <ButtonGroup style={{marginLeft: 8}}>
      {getAllMetricsForFood(foods?.[rid]).map(m => <Button
        variant={r.unit === m.value ? 'primary' : 'outline-primary'}
        onClick={() => {
          dispatch(updateFoodRecipe(refFood.id, {id: rid, weights: proParseFloat(m.weights), unit: m.value}, index));
        }}
      >
        {` ${m.value}` + (m.weights !== 1 ? `(${m.weights}g)` : ``)}
      </Button>)}
    </ButtonGroup>
    }
    <Cancel
      style={{
        height: 20,
        width: 20,
        marginLeft: 8,
        marginRight: 8,
        cursor: 'pointer',
      }}
      alt={''}
      src={Cancel}
      title="Delete"
      onClick={() => {
        dispatch(removeFoodRecipe(refFood.id, index));
      }}
    />
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {!isFirst && <DownArrow
        style={{
          height: 20,
          width: 20,
          marginBottom: 4,
          marginTop: 4,
          cursor: 'pointer',
        }} onClick={() => dispatch(moveFoodRecipeOrder(refFood.id, index, index - 1))}/>}
      {!isEnd && <DownArrow
        style={{
          height: 20,
          width: 20,
          cursor: 'pointer',
          marginBottom: 4,
          transform: 'rotate(180deg)',
        }} onClick={() => dispatch(moveFoodRecipeOrder(refFood.id, index, index + 1))}/>}
    </div>
  </div>;
};
const Recipes = props => {
  const dispatch = useDispatch();
  const {id} = props;
  const {foodDb: foods} = useFoodDbStore(({foodDb}) => ({foodDb}));
  const refFood = foods?.[id];
  const [selectedDate, setSelectedDate] = useState(new Date(refFood.others?.created));
  const handleDateChange = useCallback(d => {
    setSelectedDate(d);
    dispatch(updateFoodOthers(refFood.id, 'others.created', d.getTime()));
  }, [refFood.id]);
  if ((!refFood?.recipe || _.size(refFood?.recipe) === 0) && !refFood?.others) {
    return null;
  }
  // const existingLang = others.method ? others.method) : null

  return (<>
      <div style={{flexDirection: 'row', display: 'flex'}}>
        <div style={{width: '50%', paddingRight: 20}}>
          {refFood?.recipe && _.size(refFood?.recipe) > 0 && <>
            {_.isArray(refFood.recipe) ?
              refFood.recipe.map(({id, ...r}, index) => {
                return <ServingRow key={`${id}_${index}`} foods={foods} rid={id} refFood={refFood} r={r} index={index}
                                   isEnd={refFood.recipe.length - 1 === index}
                                   isFirst={index === 0}/>;
              }) : Object.entries(refFood.recipe).map(([rid, r], index) => {
                return <ServingRow key={`${rid}_${index}`} foods={foods} rid={rid} refFood={refFood} r={r} index={index}
                                   isEnd={refFood.recipe.length - 1 === index}
                                   isFirst={index === 0}/>;
              })
            }
            <div style={{fontSize: 17, fontWeight: 'bold'}}>
              Sum weight: {getRecipeFullWeight(refFood.recipe).toFixed(0)}g ({(getRecipeFullWeight(refFood.recipe).toFixed(0)) / refFood.others?.serves}g/serving)
            </div>
            {refFood.others?.serves ? <>
              <div style={{fontSize: 17, fontWeight: 'bold'}}>1
                serving: {(getRecipeContain(refFood?.recipe, 'calorie') / refFood.others?.serves).toFixed(0)}kcal
              </div>
              <MealSummary refFood={refFood}/>
            </> : <div style={{fontSize: 17, fontWeight: 'bold'}}>Serving not specified.</div>}
          </>
          }
        </div>
        <div style={{padding: 12, flex: 1, display: 'flex', flexDirection: 'column'}}>

          {refFood.others?.method && Object.keys(refFood.others.method).map(lang => <>
            <InputOthers refFood={refFood} title={'Method ' + lang} field={'others.method.' + lang} isTextarea={true}/>
            <InputOthers refFood={refFood} title={'Tips ' + lang} field={'others.tips.' + lang} isTextarea={true}/>
          </>)}
          <div>
            {['en', 'hu'].map((l) => _.get(refFood, 'others.method.' + l, null) !== null ? null :
              <div onClick={() => dispatch(updateFoodOthers(refFood.id, 'others.method.' + l, '-'))}
                   style={{color: blue['500'], fontWeight: 'bold', cursor: 'pointer'}}>+{l} recipe</div>)}
          </div>
          <div style={{marginTop: 4, marginBottom: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{marginBottom: 4, marginRight: 20}}>
              {'Difficulty'}
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                {refFood.others?.difficulty ?? 'Not selected'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {['EASY', 'MEDIUM', 'HARD', null].map(o => <Dropdown.Item onClick={() => {
                  dispatch(updateFoodOthers(refFood.id, 'others.difficulty', o));
                }}>{o ?? 'Reset difficulty'}</Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <InputOthers refFood={refFood} title={'Prep'} field={'others.prep'} unit={'mins'}/>
          <InputOthers refFood={refFood} title={'Cook'} field={'others.cook'} unit={'mins'}/>
          <InputOthers refFood={refFood} title={'Net weight'} field={'others.weight'} unit={'g'} isNumber/>
          <div style={{marginTop: 4, marginBottom: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{marginBottom: 4, marginRight: 20}}>
              {'Budget'}
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                {refFood.others?.budget ?? 'Not selected'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {['$', '$$', '$$$', '$$$$', null].map(o => <Dropdown.Item onClick={() => {
                  dispatch(updateFoodOthers(refFood.id, 'others.budget', o));
                }}>{o ?? 'Reset price'}</Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div style={{marginTop: 4, marginBottom: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{marginBottom: 4, marginRight: 20}}>
              {'Serves'}
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                {(refFood.others?.serves ?? 'Not selected') + ' serves'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[1, 2, 3, 4, 6, 8, 10, 12, 14, 16, null].map(o => <Dropdown.Item onClick={() => {
                  dispatch(updateFoodOthers(refFood.id, 'others.serves', o));
                }}>{o ? o : 'Reset serves'}</Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div style={{marginTop: 4, marginBottom: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{marginBottom: 4, marginRight: 20}}>
              {'Creation time'}
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker value={selectedDate} onChange={handleDateChange} variant="inline"/>
            </MuiPickersUtilsProvider>
          </div>
          {refFood.others?.img && <img src={refFood.others?.img} style={{width: '100%'}}/>}
          <UploadAndDisplayImage onSelect={(fileBase64, onSuccess) => {
            const uploadTask = storage.ref().child(`recipe/${id}.jpg`)
              .putString(fileBase64, 'data_url', {contentType: 'image/jpg'});
            uploadTask.on('state_changed',
              (snapshot) => {
              },
              (error) => {
                alert('Unsuccessful upload.');
                // Handle unsuccessful uploads
              },
              () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  console.log('File available at', downloadURL);
                  dispatch(updateFoodOthers(refFood.id, 'others.img', downloadURL));
                  onSuccess(downloadURL);
                });
              },
            );
          }}/>
        </div>
      </div>
    </>
  );
};
export default Recipes;
