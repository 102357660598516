import firebase from 'firebase/app';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyChSD_9K4I9CzRf1iIPNw448_h7Fvmgm2w',
  authDomain: 'diabtrend-db.firebaseapp.com',
  databaseURL: 'https://diabtrend-db.firebaseio.com',
  projectId: 'diabtrend-db',
  storageBucket: 'diabtrend-db.appspot.com',
  messagingSenderId: '589662669222',
  appId: '1:589662669222:web:e3c0e7dc73874542f46767',
  measurementId: 'G-PLG1GY4H5J',
};
const app = firebase.initializeApp(config, 'diabtrend-db'); // , 'diabtrend-db' // .performance() need a default app.

// Create facebook provider
// app.auth().useDeviceLanguage();

export default app;
// console.log('app.storage', app.storage);
// console.log('app.storage', firebase.storage);
export const storage = app.storage();
// const perf = app.performance();
export const utils = {
  FilePath: {DOCUMENT_DIRECTORY: '.', TEMP_DIRECTORY: '.'},
};
