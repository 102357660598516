import React from 'react';
import Navigation from './app-bar/Navigation';
import ReferenceFood from './selection/ReferenceFood';
import SettingsModal from './app-bar/SettingsModal';
import FoodsListKalor from './foods-list/FoodsListKalor';
import FoodRowColumn from './foods-list/FoodRowColumns';
import Popup from './Popup';
import {initFirebaseLogin, selectFoodID} from '../redux/actions';
import {connect} from 'react-redux';
import RecogTrend from './recogtrend/RecogTrend';
import {initRecogtrend, updateStatus} from '../redux/photos_actions';

class App extends React.Component {
  componentDidMount = () => {
    this.props.initFirebaseLogin();
    document.addEventListener('keydown', event => {
      const key = event.key;
      // console.log('event', event);
      if (key === 'Escape' || key === 'Esc') {
        this.props.selectFoodID(null);
      }
      if (event.ctrlKey && key === 'z') {
      }
    });
  };

  render() {
    const {
      screen,
      photos,
      kaloriFoods,
      initRecogtrend,
      updateStatus,
    } = this.props;


    return (
      <div className="App">
        {screen === 'RECOGTREND' ? (
          <RecogTrend
            photos={photos}
            foods={kaloriFoods}
            initRecogtrend={initRecogtrend}
            updateStatus={updateStatus}
          />
        ) : (
          <div>
            <div style={{marginTop: 64 + 50}}>
              <FoodsListKalor/>
            </div>
            <div style={{position: 'fixed', top: 0, width: '100%'}}>
              <Navigation/>
              <FoodRowColumn/>
              {/*<CompareFoods/>*/}
              <SettingsModal/>
            </div>
            <ReferenceFood/>
          </div>
        )}
        <Popup/>
      </div>
    );
  }
}

export default connect(
  ({photos, kaloriFoods, screen}) => ({photos, kaloriFoods, screen}),
  {initFirebaseLogin, selectFoodID, initRecogtrend, updateStatus},
)(App);
