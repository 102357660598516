import firebase from '../firebase/diabtrend-db';

const getBodyStr = data => {
  const params = Object.entries(data)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');
  return params.length > 0 ? `?${params}` : '';
};

export const get_dynlink_data = async (data, social) => {
  const api_key = 'AIzaSyChSD_9K4I9CzRf1iIPNw448_h7Fvmgm2w';
  const dynprefix = 'https://app.diabtrend.com/invite';
  const dynurl = 'https://app.diabtrend.com/invite/official';

  const urldata_str = getBodyStr(data);
  const payload_data = {
    'dynamicLinkInfo': {
      'domainUriPrefix': dynprefix,
      'link': dynurl + '/' + urldata_str,
      'androidInfo': {
        'androidPackageName': 'com.diabtrend',
        'androidMinPackageVersionCode': '8',
      },
      'iosInfo': {
        'iosBundleId': 'com.diabtrend.diabtrendapplication',
        'iosAppStoreId': '1441892352',
      },
      'navigationInfo': {
        'enableForcedRedirect': true,
      },
      ...(social ? {
        'socialMetaTagInfo': {
          'socialTitle': social.st,
          'socialDescription': social.sd,
          ...(social.si ? {'socialImageLink': social.si} : {}),
        },
      } : {}),
    },
    'suffix': {
      'option': 'UNGUESSABLE',
    },
    // ...(social ? {
    //   social: {
    //     title: social.title,
    //     descriptionText: social.descriptionText,
    //     imageUrl: social.imageUrl,
    //   },
    // } : {}),
  };
  // https://app.diabtrend.com/invite/official/?invitedby=ifSTbvxU36Pc0xQbRiLZqqxnd9Z2&screen=DiaPremium&discount=influencer&name=Diabetic_Jess+Jessyka&invitecode=zpC4fz712BpSCtFX8&photo=https://firebasestorage.googleapis.com:443/v0/b/diabtrend-db.appspot.com/o/profile%252FifSTbvxU36Pc0xQbRiLZqqxnd9Z2%252Fprofile_unnamed_1638899447.087?alt%3Dmedia%26token%3D2715780e-aa22-4cb7-a0ad-673cd7aabfa7
  const headers = {'Content-type': 'application/json'};
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${api_key}`;
  const x = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload_data),
  });
  const result = await x.json();
  // print('Warnings:', result['warning']);

  return result['shortLink'];
};
// export const createLinkData = async (dynUrl, data, onError, invitecode, social = null) => {
//   const bodyStr = getBodyStr(invitecode ? {...data, invitecode} : data);
//
//   return await firebase
//     .dynamicLinks()
//     .buildShortLink(
//       {
//         link: `https://www.diabtrend.com/invite/${bodyStr}`,
//         domainUriPrefix: 'https://app.diabtrend.com/invite',
//         android: {
//           minimumVersion: '8',
//           packageName: 'com.diabtrend',
//         },
//         ios: {
//           bundleId: 'com.diabtrend.diabtrendapplication',
//           appStoreId: '1441892352',
//         },
//         navigation: {
//           forcedRedirectEnabled: true,
//         },
//         ...(social ? {
//           social: {
//             title: social.title,
//             descriptionText: social.descriptionText,
//             imageUrl: social.imageUrl,
//           },
//         } : {}),
//       },
//       firebase.dynamicLinks.ShortLinkType.UNGUESSABLE,
//     )
//     .catch(onError);
// };
