import React from 'react';
import {conditionTypes, useColumnStore} from '../foods-list/ColumnOrder';
import {Button, Col, Dropdown, Form} from 'react-bootstrap';
import {column_selector_fns} from '../../utils/food_helper';
import {PopupContainer} from '../buildingblocks/PopupContainer';
// import {Button, Dropdown, Menu} from 'antd';

//https://react-bootstrap.github.io/forms/overview/
export const FilterPanel = ({columnKey, onClose}) => {
  const {filters, setFilters, setFiltersToggleShown} = useColumnStore();
  const filter = filters[columnKey] ?? {};
  const {options, valueRender} = column_selector_fns[columnKey];

  return <PopupContainer onClose={onClose}><Form style={{flexDirection: 'column'}}>
    <Form.Group className="mb-3">
      <Form.Label>Column: {columnKey}</Form.Label>
    </Form.Group>
    <Form.Group className="mb-3">
      <Form.Check
        checked={filter.enabled}
        onChange={e => {
          setFilters(columnKey, {enabled: !filter.enabled});
        }} label="Is enabled"/>
    </Form.Group>
    <Form.Group className="mb-3">
      <Form.Label>Condition</Form.Label>
      <Dropdown>
        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
          {filter.conditionType ?? 'Not selected'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.keys(conditionTypes).map(o => <Dropdown.Item onClick={() => {
            setFilters(columnKey, {conditionType: o});
          }}>{o}</Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
    {['LESS', 'EQUAL', 'NOTEQUAL', 'MORE'].includes(filter.conditionType) &&
      <Form.Group className="mb-3">
        <Form.Label>Value</Form.Label>
        <Form.Control type="string" value={filter.conditionValue ?? ''} placeholder="Value" onChange={(e) => {
          setFilters(columnKey, {conditionValue: e.target.value});
        }}/>
      </Form.Group>
    }
    {options && <Form.Group className="mb-3">
      <Form.Label>Possible values</Form.Label>
      <Col className="mb-2">
        <Button size="sm" variant={'outline-primary'} onClick={() => setFilters(columnKey, {valuesShown: Object.assign({}, ...options.map(o => ({[o]: true})))})}>
          Select all
        </Button>
        <Button size="sm" variant={'outline-primary'} onClick={() => setFilters(columnKey, {valuesShown: {}})}>Unselect all</Button>
      </Col>
      {options.map(o => <Form.Check
        type="checkbox"
        checked={filter?.valuesShown ? filter?.valuesShown.hasOwnProperty(o) : true}
        onChange={e => setFiltersToggleShown(columnKey, o)}
        label={valueRender ? valueRender(o) : `${o}`}
      />)}
    </Form.Group>}
    <Button onClick={onClose}>Ok</Button>
  </Form>
  </PopupContainer>;
};

// const menu = (
//   <Menu>
//     <Menu.Item>
//       <a target="_blank" rel="noopener noreferrer">
//         1st menu item
//       </a>
//     </Menu.Item>
//     <Menu.Item>
//       <a target="_blank" rel="noopener noreferrer">
//         2nd menu item
//       </a>
//     </Menu.Item>
//     <Menu.Item>
//       <a target="_blank" rel="noopener noreferrer">
//         3rd menu item
//       </a>
//     </Menu.Item>
//   </Menu>
// );
